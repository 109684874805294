import { removeVarBraces } from '.';
import { twigDateFormatOptions } from '../pages/broadcasts/constants';
import { convertDotToSquareBracketNotation } from '../pages/broadcasts/utils';
import { dateTwigTemplate } from './regex';
import { Option } from '../pages/broadcasts/types';

export const twigSyntax = `
  {% if date(profile.DateVariable) > date('1970-01-01T00:01:01') %}
  {{ profile.DateVariable | date('M d, Y') }}
  {% else %}
  Invalid Date
  {% endif %}
`;

export const getDateFormattedString = (variableMacros: string, format: string, defaultValue: string = 'Invalid Date') => {
  return `{% if ${variableMacros} and date(${variableMacros}) > date('1970-01-01T00:01:01') %}{{ ${variableMacros} | date('${format}') }}{% else %}${defaultValue}{% endif %}`;
};

export const getTwigDateFormatForVariable = (variable: any, format: string, defaultValue: string) => {
  const varWithoutBraces = removeVarBraces(variable);
  return getDateFormattedString(convertDotToSquareBracketNotation(varWithoutBraces || ''), format, defaultValue);
};

// Regex patterns for extraction
const regexPatterns = {
  dateString: /date\('1970-01-01T00:01:01'\)/im,
  elsePart: /{% else %}\s*([^%]*)\s*{% endif %}/im
};

const valueToLabelMap = twigDateFormatOptions.reduce((acc: any, option: Option) => {
  // Remove the example part from the label
  const formattedLabel = option.label.split(' (')[0];
  acc[option.value] = formattedLabel;
  return acc;
}, {});

// Function to extract values
export const extractDateFormattedValues = (text: string) => {
  const hasDateString = regexPatterns.dateString.test(text);

  const [, variable = '', dateFormat = ''] = text?.match(dateTwigTemplate) || [];
  const elsePart = (text.match(regexPatterns.elsePart) || [])[1]?.trim() || ''; // Capture group for else part
  return { hasDateString, variable: variable.trim(), dateFormat: dateFormat.trim(), elsePart };
};

export const getDateFormatterDisplayText = (variableSelected: any, selectedDateFormat: string = '', defaultValue?: string) => {
  const selectedDateFormatLabel = valueToLabelMap[selectedDateFormat] || selectedDateFormat;
  return `{${variableSelected?.name || ''}${selectedDateFormatLabel ? ` as '${selectedDateFormatLabel}'` : ''}${defaultValue ? ` | ${defaultValue}` : ''}}`;
};

