/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
// import { setTemplatesLoaded } from '../../../pages/templates/actions';
import moment from 'moment';
import { appendBroadcasts, deleteBroadcastFromSore, storeBroadcast, storeBroadcastEngagementSettings, storeBroadcasts, storeModules, storeNotifLabels } from '.';
import {
  apiIndexVariablesMap,
  donationIndexStartDate,
  broadcastNonArchivePaginationCount,
  broadcastArchivePaginationCount,
  donationUiVariables,
  timezoneTypes,
  broadcastTypes,
  broadcastConstants,
  tabsWithBroadcastsEverStarted,
} from '../../../pages/broadcasts/constants';
import { broadcastsToUI } from '../../../pages/broadcasts/transformer';
import { BroadcastTypeUI, segregatedEngagementSettingsTypeUI } from '../../../pages/broadcasts/types';
import {
  checkIfBroadcastContainsActBlueUrl,
  checkIfScheduledForPast,
  checkPlatformInstaFB,
  convertToBotTime,
  mapRefCodeToBroadcast,
  getOldestBroadcastDate,
  getSegregatedBroadcasts,
  parseBroadcastsForClickTracking,
  toSortBroadcastsAsPerScheduledDate,
  getNodeIdsFromBroadcast,
  getSegmentCostStatAsPerResponses,
} from '../../../pages/broadcasts/utils';
import { AnalyticsAPIData } from '../../../pages/mobile-home/types';
// import {
//   BroadcastTypeAPI,
//   BroadcastsResponse
// } from '../../../pages/broadcasts/types';
import { AsyncThunkDispatch, GenericObject, RootState, Thunk } from '../../../types';
import { get, delete as del, post, put } from '../../api-calls/api-client';
import { customAnalyticsAPI } from '../../api-calls/config';
import { showErrorModal } from '../../utils';
import { getTimeZone } from '../../utils/time-zones';
import { BroadcastsUI, Modules } from '../entities.types';
import _ from 'lodash';
import { getBucketLimit } from '../utils';
import { initializeRouteParamsFromUrl } from '../../page-ui-state/utils';
import { getBroadcastEngagementSettings, getSegregatedEngagement } from '../../../pages/broadcasts/hooks';
import { platforms } from '../entities.constants';
import { botConfigTypeCliqz } from '../../../pages/settings/constant';
import { dateToCheckForBroadcastToFetchClickDataFromClicksIndex } from '../../constants';
import { setPageUIState } from '../../page-ui-state/actions';

export const fetchBroadcasts = (
  accountId: string,
  botId: string,
  timezone: string,
  includeDeleted?: 'true' | 'false' | 'only',
  shouldAppendOnfetch?: boolean,
  doNotShowLoader?: boolean,
  initiateAnlyticsPagination?: boolean,
): Thunk<BroadcastsUI | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<BroadcastsUI | null> => {
    let response;
    const {
      auth: { authToken },
      pageUIState,
      entities,
      platformActionConfig,
    } = getState();
    const selectedPlatformBot = pageUIState?.selectedPlatformBot;
    const currentBot: any = selectedPlatformBot && entities?.platformBots?.[selectedPlatformBot];
    const currentAccount = entities?.account;
    const currentBotObj = currentBot?.botId && entities?.bots?.[currentBot.botId];
    const currentPlatform: string = selectedPlatformBot ? currentBot?.platform || '' : '';
    const showingNotificationBC = checkPlatformInstaFB(currentPlatform);
    try {
      response = await get(`broadcasts?botId=${botId}${includeDeleted ? `&includeDeleted=${includeDeleted}` : ''}`, authToken as string, doNotShowLoader);
      if (response && response.status === 200) {
        let filteredBroadcasts;
        if (showingNotificationBC) {
          filteredBroadcasts = response?.data?.filter((broadcast?: any) => broadcast?.jobType === broadcastTypes.NOTIFICATION);
        }

        const enableDefaultUSTargeting =
          currentBotObj?.type === botConfigTypeCliqz &&
          currentBot?.platformAsInSystemSettings === platforms.TWILIO_SMS &&
          !currentAccount?.config?.uiConfig?.[platforms.TWILIO_SMS]?.enableDefaultGlobalTargeting;

        const uiBroadcasts = broadcastsToUI(filteredBroadcasts || response.data, timezone, enableDefaultUSTargeting);
        let segregatedEngagements: segregatedEngagementSettingsTypeUI = {};
        if (uiBroadcasts) {
          const accountSettings = entities?.account?.config?.uiConfig;
          const systemSettings = platformActionConfig?.systemSettings;
          const engagementSettings = getBroadcastEngagementSettings(accountSettings, systemSettings, currentBot?.platformAsInSystemSettings);
          segregatedEngagements = getSegregatedEngagement(engagementSettings);
          dispatch(storeBroadcastEngagementSettings(segregatedEngagements));
        }
        const { recent, archive, scheduled, drafts, repeat, templates } = getSegregatedBroadcasts(uiBroadcasts, timezone || '');
        const archiveCountPaginated = Math.min(archive?.length || 0, broadcastArchivePaginationCount);
        const paginatedSortedArchiveBroadcasts: any =
          archive
            ?.sort(toSortBroadcastsAsPerScheduledDate)
            ?.slice(0, archiveCountPaginated)
            ?.map((broadcast?: BroadcastTypeUI) => broadcast) || [];

        const scheduledBroadcastEverStarted = [...scheduled, ...drafts, ...templates, ...repeat]?.filter((broadcast: any) =>
          checkIfScheduledForPast(broadcast.startDate, timezone, broadcast.timeZone),
        );
        const url = window.location.href?.split('/');
        const currentActiveTab = url?.[url?.length - 1]?.split('?')?.[0];
        let scheduledEverBroadcastList =
          currentActiveTab === broadcastConstants.DRAFT
            ? drafts || []
            : currentActiveTab === broadcastConstants.REPEAT
            ? repeat || []
            : currentActiveTab === broadcastConstants.SCHEDULED
            ? scheduled || []
            : currentActiveTab === broadcastConstants.TEMPLATES
            ? templates || []
            : [];
        !currentActiveTab && !scheduledEverBroadcastList?.length && (scheduledEverBroadcastList = scheduledBroadcastEverStarted);
        const paginatedSortedScheduledBroadcastEverStarted =
          scheduledEverBroadcastList
            ?.sort(toSortBroadcastsAsPerScheduledDate)
            ?.slice(0, Math.min(scheduledEverBroadcastList?.length || 0, broadcastNonArchivePaginationCount))
            ?.filter((broadcast: any) => checkIfScheduledForPast(broadcast.startDate, timezone, broadcast.timeZone))
            ?.map((broadcast?: BroadcastTypeUI) => broadcast) || [];
        const paginatedSortedRecentBroadcast =
          recent
            ?.sort(toSortBroadcastsAsPerScheduledDate)
            ?.slice(0, Math.min(recent?.length || 0, broadcastNonArchivePaginationCount))
            ?.map((broadcast?: BroadcastTypeUI) => broadcast) || [];

        // fetch broadcastId from URL
        const { broadcastId } = initializeRouteParamsFromUrl();
        // check in broadcast with broadcastId and get broadcast data based on broadcastId
        const activeBroadcast = uiBroadcasts && broadcastId && Object?.values(uiBroadcasts)?.find((item: BroadcastTypeUI) => item.id === broadcastId);
        const broadcastsForAnalytics: BroadcastTypeUI[] = [
          // ...recent,
          // ...scheduledBroadcastEverStarted,
          ...(!initiateAnlyticsPagination ? recent || [] : paginatedSortedRecentBroadcast),
          ...(currentActiveTab === broadcastConstants.COMPLETED ? (!initiateAnlyticsPagination ? archive || [] : paginatedSortedArchiveBroadcasts) : []),
          ...(tabsWithBroadcastsEverStarted?.includes(currentActiveTab)
            ? !initiateAnlyticsPagination
              ? scheduledBroadcastEverStarted || []
              : paginatedSortedScheduledBroadcastEverStarted
            : []),
        ]?.filter((broadcast: BroadcastTypeUI) => !!broadcast?.id);
        let broadcastsDataForAnalytics = [];
        if (activeBroadcast) {
          // check broadcastsForAnalytics for broadcastId is available or not case of refresh page
          if (broadcastsForAnalytics.find((item: BroadcastTypeUI) => item.id === activeBroadcast.id)) {
            broadcastsDataForAnalytics = broadcastsForAnalytics;
          } else {
            // broadcast is not available into broadcastsForAnalytics then add data for Analytics
            broadcastsDataForAnalytics = [...broadcastsForAnalytics, activeBroadcast];
          }
        } else {
          broadcastsDataForAnalytics = broadcastsForAnalytics;
        }
        await getAnalyticsForBroadcasts(
          accountId,
          botId,
          authToken as string,
          broadcastsDataForAnalytics,
          timezone,
          broadcastsDataForAnalytics,
          segregatedEngagements,
          !!showingNotificationBC,
          Object.keys(uiBroadcasts || {})?.length,
        );

        if (shouldAppendOnfetch) {
          dispatch(appendBroadcasts(uiBroadcasts));
        } else {
          dispatch(storeBroadcasts(uiBroadcasts));
        }
        return uiBroadcasts;
      }
      return null;
    } catch (error: any) {
      showErrorModal(error, dispatch);
      return null;
    }
  };
};

export const getDripById = (dripId: string, onDone?: (res?: any) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `drips/${dripId}?includeDeleted=true`;
    const {
      auth: { authToken },
    } = getState();
    let response;
    try {
      response = await get(url, authToken as string);
      if (response) {
        onDone && onDone(response?.data);
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
      onDone && onDone({ error });
    }
  };
};

export const getBroadcast = (broadcastId: string, onDone?: (res?: any) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `broadcasts/${broadcastId}?includeDeleted=true`;
    const {
      auth: { authToken },
    } = getState();
    let response;

    try {
      response = await get(url, authToken as string);
      if (response) {
        onDone?.(response?.data);
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
      onDone?.({ error });
    }
  };
};
export const getBroadcastById = (broadcastId: string, timezone: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `broadcasts/${broadcastId}`;
    const {
      auth: { authToken },
      pageUIState,
      entities,
    } = getState();
    let response;
    const selectedPlatformBot = pageUIState?.selectedPlatformBot;
    const currentBot: any = selectedPlatformBot && entities?.platformBots?.[selectedPlatformBot];
    const currentAccount = entities?.account;
    const currentBotObj = currentBot?.botId && entities?.bots?.[currentBot.botId];
    const enableDefaultUSTargeting =
      currentBotObj?.type === botConfigTypeCliqz &&
      currentBot?.platformAsInSystemSettings === platforms.TWILIO_SMS &&
      !currentAccount?.config?.uiConfig?.[platforms.TWILIO_SMS]?.enableDefaultGlobalTargeting;
    const broadcast = entities.broadcasts[broadcastId];
    try {
      response = await get(url, authToken as string);
      if (response) {
        const uiBroadcast = broadcastsToUI([response.data], timezone, enableDefaultUSTargeting);
        const newBroadcast: any = { ...uiBroadcast[broadcastId] };
        if (broadcast) {
          const engagementData = broadcast.engagementStats;
          const analyticsData = broadcast.analytics;
          newBroadcast.engagementStats = engagementData;
          newBroadcast.analytics = analyticsData;
        }
        dispatch(storeBroadcast(newBroadcast));
      }
      return response?.data;
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const updateBroadcastIfNecessary = () => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const broadcastId: any = state.pageUIState.broadcastYetToUpdate;
    const timezone = state.entities.account?.timezone || '';
    if (broadcastId) {
      await dispatch(getBroadcastById(broadcastId, timezone));
      dispatch(setPageUIState({ key: 'broadcastYetToUpdate', value: undefined }));
    }
  };
};

export const deleteBroadcast = (broadcastId: string, onDone?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `broadcasts/${broadcastId}`;
    const {
      auth: { authToken },
    } = getState();
    let response;

    try {
      response = await del(url, authToken as string);
      if (response) {
        dispatch(deleteBroadcastFromSore(broadcastId));
        onDone && onDone();
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};
// export const getAnalyticsForBroadcast = (accountId: string, botId: string, broadcastObj: any, timezone: string): Thunk => {
//   return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
//     const {
//       auth: { authToken },
//     } = getState();

//     const broadcast = [broadcastObj];
//     const transformedDataP = getAnalyticsForBroadcasts(accountId, botId, authToken as string, broadcast, timezone)
//       .then(() => {
//         return broadcast;
//       });

//     const val = Promise.resolve(transformedDataP);
//     val.then((data: any) => {
//       dispatch(updateBroadcastsAnalytics(data));
//     });
//   };
// };

// export const getAnalyticsForActiveBroadcasts = (accountId: string, botId: string, broadcasts: any, timezone: string): Thunk => {
//   return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
//     const {
//       auth: { authToken },
//     } = getState();
//     const transformedDataP = getAnalyticsForBroadcasts(accountId, botId, authToken as string, broadcasts, timezone)
//       .then(() => {
//         return broadcasts;
//       });

//     const val = Promise.resolve(transformedDataP);
//     val.then((data: any) => {
//       dispatch(updateBroadcastsAnalytics(data));
//     });
//   };
// };

//function getAnalyticsForBroadcasts(analyticsArr, accountId, botId, obj, startDate, endDate, token, UIformatObj, transformedBroadcasts){

/* eslint-disable max-len*/
export function getAnalyticsForBroadcasts(
  accountId: string,
  botId: string,
  token: string,
  activeBroadcasts: BroadcastTypeUI[],
  timezone: string,
  broadcastsForAnalytics?: BroadcastTypeUI[],
  segregatedEngagements?: segregatedEngagementSettingsTypeUI,
  useNotificationIndex?: boolean,
  allBroadcastsLength?: number,
) {
  const broadcastIdsForAnalytics = broadcastsForAnalytics?.map((broadcast: BroadcastTypeUI) => broadcast?.id);
  timezone = getTimeZone(timezone);
  const startDate = (activeBroadcasts?.length > 0 && getOldestBroadcastDate(activeBroadcasts)) || moment.tz(timezone).add('-30', 'days').format('YYYYMMDD');
  const endDate = moment.tz(timezone).add('2', 'days').format('YYYYMMDD'); //adding 2 days to endDate to avoid timezone issue if any
  if (!(activeBroadcasts && activeBroadcasts.length)) {
    return Promise.resolve();
  }
  const bucketLimit = getBucketLimit(startDate, endDate);
  const commonPayload = {
    startDate,
    endDate,
    accountId,
    botId: botId && botId !== 'All' ? botId : '',
    groupByBucketLimit: Math.max(allBroadcastsLength || 0, bucketLimit),
  };

  let aggregationFieldsForAllMetrics: string[] = apiIndexVariablesMap.broadcasts;
  const broadcastIndexVaribles = segregatedEngagements?.variablesNeededForIndex?.broadcasts ? segregatedEngagements?.variablesNeededForIndex?.broadcasts : [];
  const clicksIndexVariables = segregatedEngagements?.variablesNeededForIndex?.clicks || [];
  aggregationFieldsForAllMetrics = Array.from(new Set([...aggregationFieldsForAllMetrics, ...broadcastIndexVaribles]));
  const payloadForAllMetrics = {
    ...commonPayload,
    filters: broadcastsForAnalytics ? [{ field: 'broadcastId', eq: broadcastIdsForAnalytics }] : [],
    aggregationFields: aggregationFieldsForAllMetrics,
    indexName: 'broadcasts',
    groupByBucketLimit: Math.max(allBroadcastsLength || 0, bucketLimit),
    groupByField: 'broadcastId',
  };
  const broadcastToFetchClickDataFromClickIndex = broadcastsForAnalytics?.filter((brd: BroadcastTypeUI) =>
    brd?.scheduledDateAndTime || brd?.createdDate
      ? moment(brd.scheduledDateAndTime || brd.createdDate).isBefore(moment(dateToCheckForBroadcastToFetchClickDataFromClicksIndex)) && !Boolean(brd?.engagementStats?.clicks)
      : false,
  );
  const allBroadcastNodeIds = getNodeIdsFromBroadcast(broadcastToFetchClickDataFromClickIndex || []);
  const payloadForClicks = {
    ...commonPayload,
    filters: broadcastsForAnalytics
      ? [
          {
            field: 'actionNode',
            eq: allBroadcastNodeIds,
          },
        ]
      : [],
    aggregationFields: Array.from(new Set(['clicks', ...clicksIndexVariables])),
    indexName: 'clicks',
    groupByBucketLimit: Math.max(allBroadcastNodeIds?.length || 0, bucketLimit),
    groupByField: 'actionNode',
  };
  const payloadForSubscription = {
    ...commonPayload,
    filters: [
      {
        field: 'sessionStartType',
        eq: ['broadcast'],
      },
      ...(broadcastsForAnalytics ? [{ field: 'sessionStartId', eq: broadcastIdsForAnalytics }] : []),
    ],
    aggregationFields: useNotificationIndex ? apiIndexVariablesMap.notificationBroadcastOptIns : apiIndexVariablesMap.broadcastsSubscriptions,
    indexName: useNotificationIndex ? 'notifications' : 'subscriptions',
    groupByBucketLimit: Math.max(broadcastIdsForAnalytics?.length || 0, bucketLimit),
    groupByField: 'sessionStartId',
  };

  const donationAggregationFields: any = new Set([...apiIndexVariablesMap.broadcastsDonations, ...(segregatedEngagements?.variablesNeededForIndex?.donation || [])]);

  const { clickTrackEnabledbroadcasts, clickTrackDisabledbroadcasts, allDisabledClicksRefCodes, refcodeBroadcastMapping } = parseBroadcastsForClickTracking(broadcastsForAnalytics);
  const payloadForDonation = {
    ...commonPayload,
    filters: Object.keys(clickTrackEnabledbroadcasts)?.length
      ? [
          {
            field: 'broadcastId',
            eq: Object.keys(clickTrackEnabledbroadcasts),
          },
        ]
      : [],
    startDate:
      (Object.keys(clickTrackEnabledbroadcasts || {})?.length > 0 && getOldestBroadcastDate(Object?.values(clickTrackEnabledbroadcasts || {}))) ||
      moment.tz(timezone).add('-30', 'days').format('YYYYMMDD'),
    groupByBucketLimit: Object.keys(clickTrackEnabledbroadcasts || {})?.length || bucketLimit,
    aggregationFields: Array.from(donationAggregationFields),
    indexName: 'donations',
    groupByField: 'broadcastId',
  };
  const payloadForDonationRefcodes = {
    ...commonPayload,
    startDate:
      (Object.keys(clickTrackDisabledbroadcasts || {})?.length > 0 && getOldestBroadcastDate(Object?.values(clickTrackDisabledbroadcasts || {}))) ||
      moment.tz(timezone).add('-30', 'days').format('YYYYMMDD'),
    groupByBucketLimit: allDisabledClicksRefCodes?.length,
    filters: [
      {
        field: 'refcodes.name',
        eq: ['refcode'],
        nestedFieldName: 'refcodes',
      },
      {
        field: 'refcodes.value',
        eq: allDisabledClicksRefCodes,
        nestedFieldName: 'refcodes',
      },
    ],
    aggregations: [
      {
        aggregation: {
          field: 'refcodes.value',
          aggregationType: 'terms',
          aggregationName: 'refcodes.value',
          bucketLimit: allDisabledClicksRefCodes?.length * 10,
        },
        nestedAggregations: [
          {
            field: 'donationAmount',
            aggregationType: 'sum',
            aggregationName: 'donationAmount',
          },
          {
            field: 'donationCount',
            aggregationType: 'sum',
            aggregationName: 'donationCount',
          },
          {
            field: 'refundAmount',
            aggregationType: 'sum',
            aggregationName: 'refundAmount',
          },
          {
            field: 'refundCount',
            aggregationType: 'sum',
            aggregationName: 'refundCount',
          },
        ],
        nestedFieldName: 'refcodes',
      },
    ],
    indexName: 'donations',
    groupByField: 'refcodes',
  };
  const analyticsArr = [];
  const url = `${customAnalyticsAPI}`;
  const fetchAnalyticsSummaryData = post(url, payloadForAllMetrics, token, true);
  const fetchAnalyticsClicksDataFromClicksIndex = post(url, payloadForClicks, token, true);
  const fetchAnalyticsSubscriptionData = post(url, payloadForSubscription, token, true);
  const fetchAnalyticsDonationsData = Object.keys(clickTrackEnabledbroadcasts || {})?.length && post(url, payloadForDonation, token, true);
  const fetchAnalyticsDonationsDataFromRefcodes = allDisabledClicksRefCodes?.length && post(url, payloadForDonationRefcodes, token, true);
  const broadCastKeyMapper = Array.from(
    new Set([
      'sent',
      'engaged',
      'delivered',
      'clicks',
      'broadcasts',
      ...apiIndexVariablesMap.broadcastsSubscriptions,
      ...broadcastIndexVaribles,
      ...clicksIndexVariables,
      ...apiIndexVariablesMap.broadcastsDonations,
    ]),
  );
  Object.keys(clickTrackEnabledbroadcasts || {})?.length
    ? analyticsArr.push(fetchAnalyticsSummaryData, fetchAnalyticsSubscriptionData, fetchAnalyticsDonationsData, fetchAnalyticsClicksDataFromClicksIndex)
    : analyticsArr.push(fetchAnalyticsSummaryData, fetchAnalyticsSubscriptionData, fetchAnalyticsClicksDataFromClicksIndex);
  allDisabledClicksRefCodes?.length && analyticsArr.push(fetchAnalyticsDonationsDataFromRefcodes);
  const fetchAnalyticsPromises = Promise.all(analyticsArr).then((broadcastData: any) => {
    const [summaryDataResponse, subscriptionDataResponse, donationsDataResponse, clicksDataFromClicksIndex, donationsDataFromRefcodes] = broadcastData || [];
    const mergedData = _.keyBy(summaryDataResponse?.data || [], 'key');
    const clicksDataOfClicksIndex = _.keyBy(clicksDataFromClicksIndex?.data || [], 'key'); // key here is nodeName
    let subscriptionData: any = {};

    // since notification index has different key
    if (useNotificationIndex) {
      if (subscriptionDataResponse?.data) {
        subscriptionData = subscriptionDataResponse.data.reduce((acc: any, curr: any) => {
          if (!curr) {
            return acc;
          }
          const subscriptionData = {
            key: curr['key'],
            new: curr['optedIn'],
            blocked: curr['blocked'],
            canceled: (curr['optedOut'] || 0) + (curr['expired'] || 0), //curr["blocked"] is added in optOut so not using this:  (curr["optedOut"] || 0) + (curr["blocked"] || 0) + (curr["expired"] || 0),
          };
          acc[curr['key']] = subscriptionData;
          return acc;
        }, {});
      }
    } else {
      subscriptionData = _.keyBy(subscriptionDataResponse?.data || [], 'key'); // key here is nodeName
    }
    const donationDataForClicksBroadcasts = _.keyBy(donationsDataResponse?.data || [], 'key'); // key here is nodeName
    // const donationDataForClicksBroadcasts = _.keyBy([{ donationAmount: 106161.73, donationCount: 20, refundAmount: 20, refundCount: 2, key: '65822fea0d574f3789aca0a7' }], 'key');

    const donationData = mapRefCodeToBroadcast(donationsDataFromRefcodes?.data || [], refcodeBroadcastMapping, donationDataForClicksBroadcasts); //

    return activeBroadcasts.map((broadcast: any) => {
      const broadcastContainsDonationLink = checkIfBroadcastContainsActBlueUrl(broadcast);
      const broadcastDate: any =
        broadcast?.date &&
        broadcast?.time &&
        (broadcast?.timeZone === timezoneTypes.USER
          ? moment.utc(broadcast?.date + ' ' + broadcast?.time, 'YYYY-MM-DD HH:mm')
          : convertToBotTime(undefined, timezone, broadcast?.date, broadcast?.time));
      const utilizeBroadcastDonation = moment(broadcastDate)?.isAfter(donationIndexStartDate) && (broadcastContainsDonationLink || !!broadcast?.refcodes?.length);
      const bSummaryData = {
        ...((mergedData && mergedData[broadcast.id]) || {}),
        ...((subscriptionData && subscriptionData[broadcast.id]) || {}),
        ...(utilizeBroadcastDonation ? donationData?.[broadcast.id] || {} : { hideStats: donationUiVariables }),
      };

      const { allowMmsSegmentCostStat = false, allowSmsSegmentCostStat = false } = getSegmentCostStatAsPerResponses(broadcast);
      if (!allowMmsSegmentCostStat) {
        bSummaryData.hideStats = [...(bSummaryData.hideStats || []), 'estimatedMMSCost'];
      }
      if (!allowSmsSegmentCostStat) {
        bSummaryData.hideStats = [...(bSummaryData.hideStats || []), 'estimatedSMSSegmentCost'];
      }
      // add clicks data separately
      const totalClicksForBC = (broadcast?.nodes as Array<any>).reduce((acc: number, current: string) => acc + (clicksDataOfClicksIndex?.[current]?.clicks || 0), 0);
      const broadcastMetric: any[] = [];
      broadCastKeyMapper.forEach((bKey: string) => {
        const metricObj: GenericObject = {};
        metricObj.metric = bKey;
        metricObj.number = bSummaryData[bKey] || 0;
        broadcastMetric.push(metricObj);
      });
      if (Number.isInteger(totalClicksForBC) && totalClicksForBC > bSummaryData.clicks) {
        bSummaryData.clicks = totalClicksForBC;
      }
      broadcast.analytics = broadcastMetric;
      broadcast.engagementStats = { sent: 0, engaged: 0, delivered: 0, clicks: 0, broadcasts: 0, read: 0, ...bSummaryData };
      return broadcast;
    });
  });
  return Promise.all([fetchAnalyticsPromises]);
}

export const getBroadcastMessagePreview = (platformUser: any, broadcastData: any, successCallBack: any = () => {}): Thunk<any> => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<AnalyticsAPIData[] | null> => {
    const url = `messaging/generateMessages`;
    const {
      auth: { authToken },
    } = getState();
    let response;

    const { platform, platformUserId, platformBotId } = platformUser || {};
    const { id: broadcastId, broadcastNodeId, nodes: nextNodes } = broadcastData || {};
    const body = {
      platform,
      platformUserId,
      platformBotId,
      broadcast: true,
      nextNodes,
      broadcastInfo: {
        broadcastId,
        broadcastNodeId,
      },
    };
    try {
      response = await post(url, body, authToken as string);
      if (response) {
        successCallBack?.(response.data?.[0]?.data);
        return response.data?.[0]?.data;
      }
    } catch (error: any) {
      // showErrorModal(error, dispatch);
      return null;
    }
    return null;
  };
};

export const getBroadcastSessionMetrics = (accountId: string, botId: string, timezone: string, uptoDays: number): Thunk<AnalyticsAPIData[] | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<AnalyticsAPIData[] | null> => {
    const url = `analytics/session`;
    const {
      auth: { authToken },
    } = getState();
    let response;
    timezone = getTimeZone(timezone);
    const startDate = Number(moment.tz(timezone).add(`-${uptoDays}`, 'days').format('YYYYMMDD'));
    const endDate = Number(moment.tz(timezone).add('-1', 'days').format('YYYYMMDD'));
    const body = {
      startDate,
      endDate,
      accountId,
      botId,
      filters: [{ field: 'sessionStartType', eq: ['broadcast'] }],
    };
    try {
      response = await post(url, body, authToken as string);
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
      return null;
    }
    return null;
  };
};

export const fetchAnalyticsOther = (payload: any): Thunk<any[] | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any[] | null> => {
    const {
      auth: { authToken },
    } = getState();
    let response;
    try {
      response = await post(customAnalyticsAPI, payload, authToken as string);
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
      return null;
    }
    return null;
  };
};

export const fetchModules = (versionId: string, toStoreModules?: boolean): Thunk<Modules | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<Modules | null> => {
    const {
      auth: { authToken },
    } = getState();
    let response;
    try {
      response = await get(`modules?versionId=${versionId}`, authToken as string);
      if (response) {
        const allModules: Modules = {};
        response?.data?.length && Object.values(response.data)?.forEach((eachModule: any) => eachModule?.id && (allModules[eachModule.id] = eachModule));
        toStoreModules && dispatch(storeModules({ versionId, modules: allModules }));
        return allModules;
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
      return null;
    }
    return null;
  };
};

export const fetchModuleFromId = (moduleId: string, versionId: string, callback?: (response: any) => void, showError: boolean = true): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const { auth } = getState();
    const authToken = auth.authToken;
    let response;
    try {
      response = await get(`modules/${moduleId}?versionId=${versionId}`, authToken as string, false);
      if (response?.data) {
        callback && callback(response?.data);
      }
    } catch (error: any) {
      callback?.({ error });
      if (showError) {
        showErrorModal(error, dispatch, false);
      }
    }
  };
};

export const fetchExternalTemplates = (
  platformBotId: string,
  platform?: string,
  successCallBack?: (isSuccess: boolean, response: any) => void,
  showError: boolean = false,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const { auth } = getState();
    const authToken = auth.authToken;
    let response;
    try {
      response = await get(`externalPlatformTemplates/botPlatformTemplates?platformBotId=${encodeURIComponent(platformBotId)}&platform=${platform}`, authToken as string, false);
      if (response?.data) {
        successCallBack?.(true, response.data);
      }
    } catch (error: any) {
      successCallBack?.(false, { error });
      if (showError) {
        showErrorModal(error, dispatch, false);
      }
    }
  };
};

export const fetchVersionDetails = (versionId: string, toStoreNotifLabels?: boolean): Thunk<any[] | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any[] | null> => {
    const {
      auth: { authToken },
    } = getState();
    let response;
    try {
      response = await get(`versions/${versionId}`, authToken as string);
      if (response) {
        toStoreNotifLabels && dispatch(storeNotifLabels({ versionId, notificationLabels: response?.data?.notificationLabels }));
        return response.data;
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
      return null;
    }
    return null;
  };
};

export const cloneModules = (modules: string[], botId: string, callback: (clonedModules: string[], isSuccess: boolean) => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any[] | null> => {
    const {
      auth: { authToken },
      entities: { bots },
    } = getState();
    const versionId = bots?.[botId]?.activeVersionId;
    if (versionId && modules.length) {
      const moduleUrls = modules.map((moduleId: string) => `modules/${moduleId}/clone?versionId=${versionId}`);
      const responses = await Promise.allSettled(moduleUrls.map((url: string) => post(url, null, authToken as string)));
      const failedCloningModuleIds = [];
      const clonedModuleIds: string[] = [];
      responses.forEach((response: any, index: number) => {
        if (response.value?.status === 200) {
          clonedModuleIds.push((response.value.data.id || '') as string);
        } else {
          failedCloningModuleIds.push(modules[index]);
        }
      });
      if (failedCloningModuleIds.length) {
        dispatch(deleteModules(clonedModuleIds, botId));
        callback([], false);
      } else {
        callback(clonedModuleIds, true);
        dispatch(updatePackagesWithNewModules(modules, clonedModuleIds, versionId));
      }
    } else {
      callback([], !modules.length);
    }
    return null;
  };
};

export const updatePackagesWithNewModules = (modules: string[], clonedModuleIds: string[], versionId: string) => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any[] | null> => {
    const {
      auth: { authToken },
    } = getState();
    try {
      const packageResponse = await get(`packages?versionId=${versionId}`, authToken as string);
      if (packageResponse?.data) {
        const packages = packageResponse.data.packages;
        const changedPackages: any = {};
        packages.forEach((indPackage: any) => {
          const existingModuleIds = indPackage.moduleIds.slice();
          existingModuleIds.forEach((module: any) => {
            const moduleIndex = modules.indexOf(module);
            if (moduleIndex > -1) {
              if (changedPackages[indPackage.id]) {
                changedPackages[indPackage.id].moduleIds.push(clonedModuleIds[moduleIndex]);
              } else {
                changedPackages[indPackage.id] = {
                  name: indPackage.name,
                  moduleIds: [...indPackage.moduleIds, clonedModuleIds[moduleIndex]],
                  id: indPackage.id,
                  versionId,
                  lastUpdatedDate: indPackage.lastUpdatedDate,
                };
              }
            }
          });
        });
        Object.values(changedPackages).forEach((changedPackage: any) => {
          put(`packages`, changedPackage, authToken as string);
        });
      }
    } catch (ex) {}
    return null;
  };
};

export const deleteModules = (modules: string[], botId: string) => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any[] | null> => {
    const {
      auth: { authToken },
      entities: { bots },
    } = getState();
    const versionId = bots?.[botId]?.activeVersionId;
    if (versionId && modules.length) {
      modules.forEach((moduleId: string) => {
        del(`modules/${moduleId}?versionId=${versionId}`, authToken as string);
      });
    }
    return null;
  };
};
