import * as userActions from '.';
import { showErrorModal } from '../../../common/utils';
import { post, delete as del, put, get } from '../../../common/api-calls/api-client';

import { AsyncThunkDispatch, RootState, Thunk, Auth, User } from '../../../types';
import { usersToFrontEnd, transformUserToApi, transformUserRoleToUI } from '../transformer';
import { botUserUIType, RoleApiType, UserRoleToApiType, UserUIType } from '../type';
import { storeUserDetails, storeUserRoles } from '../../login/actions';
import { showMessage } from '../../common/toast-notification';
import { DoneAction, SuccessText } from '../../common/toast-notification/toast-notification.constants';
import { userRolesLite } from '../components/manage-users/constants';
import { getExistingUserId } from '../utils';
import { USER_EXISTS_MSG } from './constants';
import { PlatformBot } from '../../../common/entities/entities.types';

const findRolesToAddOrRemove = (usersCurrentRoles: RoleApiType[], editedroles: UserRoleToApiType[]) => {
  const rolesToRemove = usersCurrentRoles.filter(
    (role: RoleApiType) =>
      !editedroles.some((editedRole: UserRoleToApiType) => {
        return editedRole.bot === role.bot;
      }),
  );
  const findrolesToadd = editedroles.filter(
    (role: UserRoleToApiType) =>
      !usersCurrentRoles.some((editedRole: RoleApiType) => {
        return editedRole.bot === role.bot;
      }),
  );
  return { rolesToRemove, findrolesToadd };
};

export const getUsers = (onGetUsersSuccess?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities } = getState();
    const accountId = entities.account?.id;
    const url = `dashboardUsers?accountId=${accountId}&fetchRoles=true`;

    const authToken = auth.authToken;
    let response = null;
    try {
      /*added to clear previous account users if any so that failure doesnt lead to showing older users*/
      dispatch(userActions.storeUsers({ usersToUI: {}, usersSegregated: { accountOwner: [], botOwner: [] } }));
      response = await get(url, authToken as string);

      if (response && response.data) {
        dispatch(userActions.storeUsers(usersToFrontEnd(response.data, entities.account)));
        onGetUsersSuccess && onGetUsersSuccess();
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const getBotUsers = (botId: string, onComplete?: (isSuccess: boolean) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities } = getState();
    const accountId = entities.account?.id;
    const url = `accounts/${accountId}/users${botId ? '?botId=' + botId : ''}`;

    const authToken = auth.authToken;
    let response: null | { data: botUserUIType[] } = null;
    try {
      dispatch(userActions.storeBotUsers(botId, []));
      response = await get(url, authToken as string);

      if (response && response.data) {
        dispatch(userActions.storeBotUsers(botId, response.data));
        onComplete?.(true);
        return;
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
    onComplete?.(false);
  };
};

export const getCurrentUserRoles = (onGetRolesSuccess?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth } = getState();
    const userId = auth.user?.id;
    const url = `dashboardUsers/${userId}/roles`;
    const authToken = auth.authToken;
    let response = null;
    try {
      response = await get(url, authToken as string);

      if (response && response.data) {
        dispatch(storeUserRoles(transformUserRoleToUI(response.data)));
        onGetRolesSuccess && onGetRolesSuccess();
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const deleteUser = (userId: string, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `dashboardUsers/${userId}`;
    const { auth } = getState();
    const authToken = auth.authToken;
    let response = null;
    try {
      response = await del(url, authToken as string);
      if (response) {
        // dispatch(userActions.deleteUser(userId));
        dispatch(getUsers());
        done && done();
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const getUserRoles = async (userId: string, auth: Auth, dispatch: AsyncThunkDispatch) => {
  const url = `dashboardUsers/${userId}/roles`;
  const authToken = auth.authToken;
  let response = null;
  try {
    response = await get(url, authToken as string);
    if (response && response.data) {
      return response.data;
      // dispatch(userActions.setRoles(userId));
    }
  } catch (error: any) {
    showErrorModal(error, dispatch);
    return [];
  }
};

export const deleteUserRole = (userId: string, roleId: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `dashboardUsers/${userId}/roles/${roleId}`;
    const { auth } = getState();
    const authToken = auth.authToken;
    let response = null;
    try {
      response = await del(url, authToken as string);
      if (response && response.data) {
        // dispatch(userActions.deleteUserRole(userId));
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};
/* eslint-disable max-lines-per-function */
export const createUser = (userObj: UserUIType, usersCurrentRoles: RoleApiType[], overriddenRoles: any, done?: () => void, handleCancel?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities } = getState();
    const authToken = auth.authToken;
    const url = `dashboardUsers`;
    let method;
    const apiUser = transformUserToApi(userObj);
    let roleUpdationResponse = null;
    const users = entities.users;
    const currAccount = entities.account?.id;

    if (userObj.id) {
      method = put;
      delete apiUser.userRoles;
      if (userObj.liteRoleLabel !== 'BotOwner') {
        roleUpdationResponse = { data: true };
      }
    } else {
      method = post;
      apiUser.isAmplifyLite = true;
      roleUpdationResponse = { data: true };
    }
    let response = null;
    try {
      if (!userObj.userRoles?.length) {
        dispatch(deleteUser(apiUser.id || ''));
        return;
      }

      response = await method(url, apiUser, authToken as string);
      if (
        userObj.id &&
        (userObj.liteRoleLabel === (overriddenRoles?.teamMember?.role ? overriddenRoles?.teamMember?.role : userRolesLite.BOT_OWNER) ||
          userObj.liteRoleLabel === (overriddenRoles?.admin?.role ? overriddenRoles?.admin?.role : userRolesLite.ACCOUNT_OWNER))
      ) {
        const { rolesToRemove, findrolesToadd } = findRolesToAddOrRemove(usersCurrentRoles, userObj.userRoles || []);
        await Promise.all(
          rolesToRemove.map((role: RoleApiType) => {
            return deleteUserRoleApi(userObj.id || '', role.id || '', authToken as string);
          }),
        );
        roleUpdationResponse = findrolesToadd.length ? await createUserRoles(findrolesToadd, userObj.id || '', authToken as string) : { data: true };
      }

      if (((response && response.data) || response.status === 204) && roleUpdationResponse && roleUpdationResponse.data) {
        dispatch(getUsers());
        done && done();
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        const userId = getExistingUserId(users, userObj.email) || '';
        const userRole = userObj.userRoles || [{ role: '', account: currAccount || '' }];
        if (userId) {
          userId && dispatch(createUserRole(userRole, userId, handleCancel, showMessage));
        } else {
          userObj?.email && dispatch(getUserAndCreateRole(userObj.email, userRole, handleCancel, showMessage));
        }
      } else {
        showErrorModal(error, dispatch);
      }
    }
  };
};

export const subscribeUser = (platformBots: PlatformBot[], userData: any) => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `/platformUsers/addNewSmsBroadcastTestUser`;
    const { auth } = getState();
    const authToken = auth.authToken;
    const promises = platformBots.map((platformBot: PlatformBot) =>
      post(
        url,
        {
          botId: platformBot.botId,
          platformBotId: platformBot.platformBotId,
          platformUserId: userData?.phoneNumber,
          profileInfo: {
            firstName: userData?.firstName,
            lastName: userData?.lastName,
          },
        },
        authToken as string,
      ),
    );
    try {
      await Promise.all(promises);
    } catch (error: any) {
      //eslint-disable-next-line no-console
      console.error('Failed Subscribing to sms bots');
    }
  };
};

export const checkUserSubscriptions = (platformBots: PlatformBot[], userData: any, onSuccess: () => void, onFailure: () => void) => {
  return (_dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const url = `/platformUsers/subscriptions`;
    const {
      auth: { authToken },
    } = getState();
    Promise.all(
      platformBots.map(({ platformBotId, platform }: PlatformBot) =>
        post(
          url,
          {
            platformUserId: userData.phoneNumber,
            platformBotId,
            platform,
          },
          authToken as string,
        ),
      ),
    )
      .then((results: any[]) => {
        const botsWithSubscriptions = results.filter((response: any) => Array.isArray(response.data) && response.data.length > 0);
        if (botsWithSubscriptions.length === results.length) {
          onSuccess();
        } else {
          onFailure();
        }
      })
      .catch(onFailure);
  };
};

export const createUserRole = (role: UserRoleToApiType[], userId: string, handleCancel?: () => void, showMessage?: (m: string) => any): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth } = getState();
    const authToken = auth.authToken;
    let response = null;
    try {
      response = await createUserRoles(role, userId, authToken as string);
      if (response && response.data) {
        getUserRoles(userId, auth, dispatch);
        dispatch(getUsers());
        showMessage && showMessage(`${DoneAction.roleCreate} ${SuccessText}`);
        handleCancel && handleCancel();
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

const createUserRoles = async (roles: UserRoleToApiType[], userId: string, authToken: string) => {
  const url = `dashboardUsers/${userId}/roles`;

  return post(url, roles, authToken, false);
};

const deleteUserRoleApi = async (userId: string, roleId: string, authToken: string) => {
  const url = `dashboardUsers/${userId}/roles/${roleId}`;

  return del(url, authToken as string);
};

export const updateCurrentUser = (userObj: User, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth } = getState();
    const authToken = auth.authToken;
    const url = `dashboardUsers`;
    delete userObj.name;
    const apiUser = transformUserToApi(userObj);
    let response = null;
    try {
      response = await put(url, apiUser, authToken as string);
      if (response) {
        userObj.name = `${userObj.firstName} ${userObj.lastName}`;
        userObj.lastUpdatedDate = response.headers['x-response-time'];
        dispatch(storeUserDetails(userObj));
        showMessage(`${DoneAction.changeUserInfo} ${SuccessText}`);
        done && done();
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const changePassword = (newPassword: string, currentPassword: string, callBack?: (message: string) => void): Thunk => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth } = getState();
    const authToken = auth.authToken;
    const url = 'dashboardUsers/changeUserPassword';
    const body = {
      newPassword,
      email: getState().auth.user?.email,
      oldPassword: currentPassword,
    };
    let response = null;
    try {
      response = await post(url, body, authToken as string);
      if (response) {
        callBack && callBack('');
      }
    } catch (error: any) {
      callBack && callBack(error.response.data?.message);
    }
  };
};

export const getUserAndCreateRole = (email: string, role: UserRoleToApiType[], handleCancel?: () => void, showMessage?: (m: string) => any): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth } = getState();
    const url = `dashboardUsers/email?emailId=${email}`;

    const authToken = auth.authToken;
    let response = null;
    try {
      response = await get(url, authToken as string);

      if (response && response.data) {
        const userId = response.data._id;
        userId && dispatch(createUserRole(role, userId, handleCancel, showMessage));
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        showErrorModal(error, dispatch, false, USER_EXISTS_MSG);
      } else {
        showErrorModal(error, dispatch);
      }
    }
  };
};
