import { createAction } from '@reduxjs/toolkit';
import { PaginationValue } from '../components/type';
import constants from '../segment.constants';
import { Segments, Segment } from '../segment.types';

export const storeSegmentsBasePath = createAction<string>(constants.STORE_SEGMENTS_BASE_PATH);

export const storeSegments = createAction<Segments>(constants.STORE_SEGMENTS);

export const storeSegment = createAction<Segment>(constants.STORE_SEGMENT);

export const storeSegmentUserCount = createAction<string>(constants.STORE_SEGMENT_USER_COUNT);

export const storeSegmentsPagination = createAction<Partial<PaginationValue>>(constants.STORE_SEGMENTS_PAGINATION);

export const storeArchiveSegments = createAction<Segments>(constants.STORE_ARCHIVE_SEGMENTS);

export const storeSegmentsPaginationFromFilters = createAction<{ page: number; sort: string; size: number }>(constants.STORE_SEGMENTS_PAGINATION_FROM_FILTERS);
