import { get } from '../../api-calls/api-client';
// import { storeCampaignTagsForPlatformBot } from '.';
import { showErrorModal } from '../../utils';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';
import { PlatformBot } from '../entities.types';
// import { covertPrivateRepliesTagsToUI } from '../transformer';

export const fetchPrivateReplyTagsForCurrentPlatformBot = (): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities, pageUIState } = getState();
    const selectedPlatformBotId = pageUIState.selectedPlatformBot;
    const platformBot: { [key: string]: PlatformBot } = entities?.platformBots || {};
    let platform = '';
    let response = null;

    if (selectedPlatformBotId) {
      platform = platformBot[selectedPlatformBotId].platform;
    }
    const url = `privateReplyTags?platformBotId=${encodeURIComponent(selectedPlatformBotId || '')}&platform=${platform}&includeDeleted=false`;

    try {
      response = await get(url, auth.authToken as string);
      if (response && response.status === 200) {
        // const transformedToUI = covertPrivateRepliesTagsToUI(response.data);
        // dispatch(storeCampaignTagsForPlatformBot(transformedToUI));
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};
