import { debounce } from 'debounce';

import GraphemeSplitter from 'grapheme-splitter';
// import { SegmentsAndCharLength } from './twillio-char-counter';
// import { SegmentedMessage } from './segments-calculator';
import { SegmentedMessage } from 'sms-segments-calculator';
import Segment from 'sms-segments-calculator/dist/libs/Segment';
import { getTwilioSmartEncodedString } from './twilio-smart-encoder';

const graphemeSplitter = new GraphemeSplitter();
// const segmentsAndCharLength = new SegmentsAndCharLength();

/*character limits for segments taken from https://www.twilio.com/docs/glossary/what-sms-character-limit */
const SegmentLengthForEncoding = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'GSM-7': { single: 160, multiple: 153 },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'UCS-2': { single: 70, multiple: 67 },
};

type SegmentDetails = {
  numberOfMsgs: number;
  balanceCharacters: number;
  messageLength: number;
  segmentUtilization: number;
  message: string;
  segments: any;
  encoding: string;
  macrosUsedCount?: number;
};

export const getSegmentsCount = (message: string): SegmentDetails => {
  const segmentedMessage = new SegmentedMessage(message);
  const numberOfMsgs = segmentedMessage.segmentsCount;
  const characterCount = segmentedMessage.segments[segmentedMessage.segments.length - 1].reduce((str: string, i: any) => str + (i.raw || ''), '').length;
  const segmentLength =
    numberOfMsgs > 1 ? SegmentLengthForEncoding[segmentedMessage.encodingName]?.multiple || 153 : SegmentLengthForEncoding[segmentedMessage.encodingName]?.single || 160;
  const balanceCharacters = segmentLength - characterCount;
  const segmentUtilization = (characterCount * 100) / segmentLength;
  return {
    numberOfMsgs,
    messageLength: message.length,
    balanceCharacters,
    segmentUtilization,
    message,
    segments: segmentedMessage.segments,
    encoding: segmentedMessage.encodingName,
  };
};

export const highlightUnicodeViaHtml = (message: string): string => {
  let value = '';
  const { segments } = new SegmentedMessage(getTwilioSmartEncodedString(message).encodedString);
  segments.forEach((segment: Segment) => {
    segment.forEach((encodedChar: any) => {
      if (encodedChar.raw) {
        value += encodedChar.isGSM7 ? (encodedChar.raw !== '\n' ? encodedChar.raw : `<br />`) : `<span class="isUnicode">${encodedChar.raw}</span>`;
      }
    });
  });
  return value;
};

export const getMessageLength = (message: string) => {
  return graphemeSplitter.countGraphemes(message || '');
};

export const getSegmentsCountDebounced = debounce(getSegmentsCount, 200, true);
export const getMessageLengthDebounced = debounce(getMessageLength, 200, true);
