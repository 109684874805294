import axios, { Method, AxiosRequestConfig /*AxiosResponse, AxiosError*/ } from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { apiServerUrl } from './config';

type BaseAPICallType = {
  url: string;
  method: Method;
  authToken?: string;
  payload?: unknown;
  withHostURL?: boolean;
  doNotShowLoader?: boolean;
  responseType?: string;
};
const baseHeaders = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Content-Type': 'application/json',
};

const get = (url: string, authToken?: string, doNotShowLoader?: boolean, responseType?: string): Promise<any> => {
  return baseAPICall({ url, authToken, method: 'get', doNotShowLoader, responseType });
};
const post = (url: string, payload?: unknown, authToken?: string, doNotShowLoader?: boolean, responseType?: string): Promise<any> => {
  return baseAPICall({ url, authToken, method: 'post', payload, doNotShowLoader, responseType });
};
const put = (url: string, payload?: unknown, authToken?: string, doNotShowLoader?: boolean): Promise<any> => {
  return baseAPICall({ url, authToken, method: 'put', payload, doNotShowLoader });
};
const patch = (url: string, payload?: unknown, authToken?: string, doNotShowLoader?: boolean): Promise<any> => {
  return baseAPICall({ url, authToken, method: 'patch', payload, doNotShowLoader });
};
const deleteAlias = (url: string, authToken?: string, doNotShowLoader?: boolean): Promise<any> => {
  return baseAPICall({ url, authToken, method: 'delete', doNotShowLoader });
};
const baseAPICall = (data: BaseAPICallType): Promise<any> => {
  const { url, authToken, payload, method, doNotShowLoader, responseType } = data;
  const options = {
    method: method as string,
    responseType: responseType || 'json',
    baseURL: apiServerUrl,
    url,
    data: payload ? payload : undefined,
    headers: {
      ...baseHeaders,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ...(authToken && { 'x-access-token': authToken }),
    },
  };

  // send the request
  return doNotShowLoader ? axios(options as AxiosRequestConfig) : trackPromise(axios(options as AxiosRequestConfig));
};

export { get, post, put, patch, deleteAlias as delete };
