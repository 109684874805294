import { platforms } from '../../../../common/entities/entities.constants';
import { FBConfig } from '../../../../config';
import { IGConfig } from '../../../../config/ig-config';

export const Redirect = {
  SOCIAL_MEDIA_ACCOUNTS: 'socialMediaAccounts',
  LANDING: 'landing',
};

/*eslint-disable @typescript-eslint/naming-convention*/
export const socialMediaPermissions: { [key: string]: { [key: string]: string } } = {
  [platforms.FACEBOOK]: {
    ...FBConfig.permission.required,
    ...FBConfig.permission.optional,
    // read_page_mailboxes: 'Read and access User conversations with managed pages on Messenger',
    public_profile: 'Authenticate App Users',
    pages_messaging_phone_number: 'Manage messages from Pages you manage',
  },
  [platforms.INSTAGRAM]: {
    ...IGConfig.permission.required,
    ...IGConfig.permission.optional,
  },
};
