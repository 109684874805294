import { addTargetedUsers, deleteTargetedUser, fetchedSearchedPlatformUsers, fetchedTargetedUsers } from '.';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';
import { get, delete as del, post } from '../../api-calls/api-client';
// import { customAnalyticsAPI } from '../../api-calls/config';
import { showErrorModal } from '../../utils';

export const searchPlatformUsers = (searchObj: any, botId: string, platform: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `bots/${botId}/platformUsers?platform=${platform}`;
    const {
      auth: { authToken },
    } = getState();
    let response: any;
    try {
      response = await post(url, searchObj, authToken as string);
      if (response.data.indexOf('Unable to find platformBots for botId:') !== -1) {
        response.data = [];
      }
      dispatch(fetchedSearchedPlatformUsers({ users: response.data, botId }));
    } catch (error: any) {
      if (error.message && error.message.indexOf('Unable to find platformBots for botId:') !== -1) {
        response.data = [];
        dispatch(fetchedSearchedPlatformUsers({ users: response.data, botId }));
      } else {
        showErrorModal(error, dispatch);
      }
    }
  };
};
export const fetchTargetingUsers = (botId: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `bots/${botId}/broadcastTestUserTargetings`;
    const {
      auth: { authToken },
    } = getState();
    let response: any;
    try {
      response = await get(url, authToken as string);
      if (response && response.data) {
        dispatch(fetchedTargetedUsers({ users: response.data, botId }));
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchTargetingUserById = (botId: string, targetingId: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `bots/${botId}/broadcastTestUserTargetings/${targetingId}`;
    const {
      auth: { authToken },
    } = getState();
    let response: any;
    try {
      response = await get(url, authToken as string);
      if (response && response.data) {
        // dispatch(fetchedTargetedUsers({ users: response.data, botId }));
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const createTargetedUsers = (user: any, _botId: string, callback?: (id: string) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `broadcastTestUserTargetings`;
    const {
      auth: { authToken },
    } = getState();
    let response: any;
    try {
      response = await post(url, user, authToken as string);
      if (response?.data) {
        dispatch(addTargetedUsers(response.data));
        callback?.(response.data.id);
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const deleteTargeting = (targetingId: string, botId: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `broadcastTestUserTargetings/${targetingId}`;
    const {
      auth: { authToken },
    } = getState();
    try {
      del(url, authToken as string);
      dispatch(deleteTargetedUser({ targetingId, botId }));
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};
