import { actions } from '../../../../config/platform-action-map';
import {
  accountSettingsPageForMobile,
  socialMediaAccountPageForMobile,
  broadcastSubPagesForMobile,
  homePageForMobile,
  inboxSubPagesForMobile,
  profileSettingsPageForMobile,
  resourcePageForMobile,
} from '../../../../mobile/config';
import { timeFilterOptions } from '../../../common/time-filter/time-filter.constants';
import { getLayOutFilterParams } from '../../../../utils/filter';
import { commentFilterKeys, pageFilterKeys, pageKeysForFilter } from '../../constants';
import { encodeValue } from '../../../../common/utils/persist-time-filter';
import { Tags } from '../../../common/tag/tag.types';
import variables from '../../../../common/scss/variables.module.scss';

export const ModerationViewerTabs: { [key: string]: string } = {
  allComments: 'Total',
  actionable: 'Actionable',
  nonActionable: 'Non Actionable',
  engaged: 'Engaged',
  assignedToMe: 'Assigned To Me',
};

export const EngagementViewerTabs: { [key: string]: string } = {
  engagements: 'Engagements',
  hiddenComments: 'Hidden',
  brandReaction: 'Likes',
  publicReplies: 'Public Replies',
  privateReplies: 'Private Replies',
  blockedUsers: 'Banned Users',
  deletedComments: 'Deleted',
  reviewedComments: 'Reviewed',
  assignedComments: 'Assigned',
};

export const commentViewerTabs: { [key: string]: string } = {
  ...ModerationViewerTabs,
  labeled: 'Labeled',
  unlabeled: 'Unlabeled',
  ...EngagementViewerTabs,
};

// eslint-disable-next-line max-lines-per-function
export const defaultFiltersForPages = (preset?: { [key: string]: string }) => {
  return {
    [pageKeysForFilter.INBOX]: {
      [pageFilterKeys.FILTERS]: encodeValue({
        tabName: 'actionable',
        tag: [],
        platformUserId: '',
        layout: getLayOutFilterParams(inboxSubPagesForMobile.HOME, pageKeysForFilter.INBOX),
        ...preset,
      }),
    },
    [pageKeysForFilter.COMMENTS]: {
      [pageFilterKeys.FILTERS]: encodeValue({
        tabName: 'Total',
        tag: [],
        time: timeFilterOptions[3].value,
        startDate: undefined,
        endDate: undefined,
        timeZone: undefined,
        posts: [],
        filterBy: commentFilterKeys.TEXT,
        ...preset,
      }),
    },
    [pageKeysForFilter.BROADCASTS]: {
      [pageFilterKeys.FILTERS]: encodeValue({ layout: getLayOutFilterParams(broadcastSubPagesForMobile.HOME, pageKeysForFilter.BROADCASTS) }),
    },
    [pageKeysForFilter.PROFILE_SETTINGS]: {
      [pageFilterKeys.FILTERS]: encodeValue({ layout: getLayOutFilterParams(profileSettingsPageForMobile.HOME, pageKeysForFilter.PROFILE_SETTINGS) }),
    },
    [pageKeysForFilter.ACCOUNT_SETTINGS]: {
      [pageFilterKeys.FILTERS]: encodeValue({ layout: getLayOutFilterParams(accountSettingsPageForMobile.HOME, pageKeysForFilter.ACCOUNT_SETTINGS) }),
    },
    [pageKeysForFilter.SOCIAL_MEDIA_ACCOUNTS]: {
      [pageFilterKeys.FILTERS]: encodeValue({ layout: getLayOutFilterParams(socialMediaAccountPageForMobile.HOME, pageKeysForFilter.SOCIAL_MEDIA_ACCOUNTS) }),
    },
    [pageKeysForFilter.RESOURCES]: {
      [pageFilterKeys.FILTERS]: encodeValue({ layout: getLayOutFilterParams(resourcePageForMobile.HOME, pageKeysForFilter.RESOURCES) }),
    },
    [pageKeysForFilter.HOME]: {
      [pageFilterKeys.FILTERS]: encodeValue({ layout: getLayOutFilterParams(homePageForMobile.HOME, pageKeysForFilter.HOME) }),
    },
    [pageKeysForFilter.REPORTS]: {
      [pageFilterKeys.FILTERS]: encodeValue({
        time: timeFilterOptions[4].value,
        startDate: undefined,
        endDate: undefined,
        timeZone: undefined,
        ...preset,
      }),
    },
    [pageKeysForFilter.SEGMENTS]: {
      [pageFilterKeys.FILTERS]: encodeValue({
        page: 1,
        size: 50,
        sort: 'name',
        ...preset,
      }),
    },
  };
};
export const engagementChartLabels = {
  hiddenComments: 'Hidden',
  brandReaction: 'Likes',
  publicReply: 'Public Reply',
  privateReply: 'Private Reply',
  blockedUsers: 'Banned Users',
  deletedComments: 'Deleted',
  reviewedComments: 'Reviewed',
  assignedComments: 'Assigned',
};

export const ActionEngagementTabMapper = [
  {
    action: actions.HIDE,
    tabName: commentViewerTabs.hiddenComments,
  },
  {
    action: actions.LIKE,
    tabName: commentViewerTabs.brandReaction,
  },
  {
    action: actions.PUBLIC_REPLY,
    tabName: commentViewerTabs.publicReplies,
  },
  {
    action: actions.PRIVATE_REPLY,
    tabName: commentViewerTabs.privateReplies,
  },
  {
    action: actions.BLOCK,
    tabName: commentViewerTabs.blockedUsers,
  },
  {
    action: actions.DELETE,
    tabName: commentViewerTabs.deletedComments,
  },
  {
    action: actions.REVIEW,
    tabName: commentViewerTabs.reviewedComments,
  },
];

export const ReplyModalMode = {
  CREATE: 'create',
  TEMPLATE: 'template',
  QUICK_REPLY: 'quickReply',
  PREVIEW_REPLY: 'previewReply',
  PRIVATE_REPLY: 'privateReply',
  PRIVATE_QUICK_REPLY: 'privateQuickReply',
  PRIVATE_REPLY_TEMPLATE: 'privateReplyTemplate',
  PUBLIC_QUICK_REPLY: 'publicQuickReply',
  PUBLIC_REPLY_TEMPLAYTE: 'publicReplyTemplate',
};
export const defaultTwitterPrivateMessageButtonText = 'Send us a private message';
export const twitterPrivateReplyURL = 'https://twitter.com/messages/compose?recipient_id=';
export const twitterMessageVisibleURL = '  twitter.com/messages/compo..';
export const ReplyModalTitle = {
  [ReplyModalMode.CREATE]: 'Create New Template',
  [ReplyModalMode.TEMPLATE]: 'Reply Template',
  [ReplyModalMode.QUICK_REPLY]: 'Quick Reply',
  [ReplyModalMode.PREVIEW_REPLY]: 'Preview Reply',
  [ReplyModalMode.PRIVATE_REPLY]: 'Private Reply',
  [ReplyModalMode.PRIVATE_QUICK_REPLY]: 'Private Reply',
  [ReplyModalMode.PRIVATE_REPLY_TEMPLATE]: 'Private Reply',
  [ReplyModalMode.PUBLIC_QUICK_REPLY]: 'Public Reply',
  [ReplyModalMode.PUBLIC_REPLY_TEMPLAYTE]: 'Public Reply',
};
export const PublicReplyOptions = {
  [ReplyModalMode.CREATE]: 'Create New Template',
  [ReplyModalMode.TEMPLATE]: 'Reply Template',
  [ReplyModalMode.QUICK_REPLY]: 'Quick Reply',
};
const commentsViewerConstants: CommentsViewerConstantsType = {
  COMMENTS_VIEWER: 'COMMENTS_VIEWER',
  UPDATE_PRIMARY_TAB_COUNTS: 'UPDATE_PRIMARY_TAB_COUNTS',
  UPDATE_NON_ACTIONABLE_SECONDARY_TAB_COUNTS: 'UPDATE_NON_ACTIONABLE_SECONDARY_TAB_COUNTS',
  UPDATE_ENGAGEMENTS_SECONDARY_TAB_COUNTS: 'UPDATE_ENGAGEMENTS_SECONDARY_TAB_COUNTS',
  UPDATE_STATS_ON_CLOSE: 'UPDATE_STATS_ON_CLOSE',
  SET_COMMENT_VIEWER_CURRENT_TAB: 'SET_COMMENT_VIEWER_CURRENT_TAB',
  SET_COMMENT_VIEWER_ENGAGEMENTS_CURRENT_TAB: 'SET_COMMENT_VIEWER_ENGAGEMENTS_CURRENT_TAB',
  INDICATE_RECENT_COMMENT_MESSAGE: 'INDICATE_RECENT_COMMENT_MESSAGE',
  INDICATE_SEARCH_MESSAGE: 'INDICATE_SEARCH_MESSAGE',
  UPDATE_DISMISS_ACTION: 'UPDATE_DISMISS_ACTION',
  STORE_TEMPLATES_STRING_MAP: 'STORE_TEMPLATES_STRING_MAP',
  SET_REPLY_MODAL_MODE: 'SET_REPLY_MODAL_MODE',
  SET_REPLY_TYPE_PLUS_MODE: 'SET_REPLY_TYPE_PLUS_MODE',
  CLEAR_MESSAGE_FROM_QUICK_REPLY: 'CLEAR_MESSAGE_FROM_QUICK_REPLY',
  SET_OPEN_PREVIEW_REPLY_MODAL: 'SET_OPEN_PREVIEW_REPLY_MODAL',
  SET_REPLY_CONTENT: 'SET_REPLY_CONTENT',
  SET_INCLUDE_SEND_PRIVATE_REPLY_BUTTON: 'SET_INCLUDE_SEND_PRIVATE_REPLY_BUTTON',
  SET_PRIVATE_MESSAGE_CONTENT: 'SET_PRIVATE_MESSAGE_CONTENT',
  CLEAR_PRIVATE_MESSAGE_BUTTON_CONTENT: 'CLEAR_PRIVATE_MESSAGE_BUTTON_CONTENT',
  CREATE_TEMPLATE_NAME: 'CREATE_TEMPLATE_NAME',
  SELECTED_TEMPLATE: 'SELECTED_TEMPLATE',
  SET_MEDIA_URL: 'SET_MEDIA_URL',
  SET_REPLY_TYPE: 'SET_REPLY_TYPE',
  SET_SELECTED_COMMENT: 'SET_SELECTED_COMMENT',
  SET_SELECTED_ALL_COMMENT: 'SET_SELECTED_ALL_COMMENT',
  SET_COMMENT_IN_SINGLE_PAGE: 'SET_COMMENT_IN_SINGLE_PAGE',
  SET_SELECTED_COMMENT_PAGE: 'SET_SELECTED_COMMENT_PAGE',
  SET_SELECTED_PREVIOUS_COMMENT_PAGE: 'SET_SELECTED_PREVIOUS_COMMENT_PAGE',
  SET_SELECT_ALL_FLAG: 'SET_SELECT_ALL_FLAG',
  SET_ERROR_USER_COMMENT: 'SET_ERROR_USER_COMMENT',
};
type CommentsViewerConstantsType = {
  COMMENTS_VIEWER: string;
  UPDATE_PRIMARY_TAB_COUNTS: string;
  UPDATE_NON_ACTIONABLE_SECONDARY_TAB_COUNTS: string;
  UPDATE_ENGAGEMENTS_SECONDARY_TAB_COUNTS: string;
  UPDATE_STATS_ON_CLOSE: string;
  SET_COMMENT_VIEWER_CURRENT_TAB: string;
  SET_COMMENT_VIEWER_ENGAGEMENTS_CURRENT_TAB: string;
  INDICATE_RECENT_COMMENT_MESSAGE: string;
  INDICATE_SEARCH_MESSAGE: string;
  UPDATE_DISMISS_ACTION: string;
  STORE_TEMPLATES_STRING_MAP: string;
  SET_REPLY_MODAL_MODE: string;
  SET_REPLY_TYPE_PLUS_MODE: string;
  CLEAR_MESSAGE_FROM_QUICK_REPLY: string;
  SET_OPEN_PREVIEW_REPLY_MODAL: string;
  SET_REPLY_CONTENT: string;
  SET_INCLUDE_SEND_PRIVATE_REPLY_BUTTON: string;
  SET_PRIVATE_MESSAGE_CONTENT: string;
  CLEAR_PRIVATE_MESSAGE_BUTTON_CONTENT: string;
  CREATE_TEMPLATE_NAME: string;
  SELECTED_TEMPLATE: string;
  SET_MEDIA_URL: string;
  SET_REPLY_TYPE: string;
  SET_SELECTED_COMMENT: string;
  SET_SELECTED_ALL_COMMENT: string;
  SET_COMMENT_IN_SINGLE_PAGE: string;
  SET_SELECTED_COMMENT_PAGE: string;
  SET_SELECTED_PREVIOUS_COMMENT_PAGE: string;
  SET_SELECT_ALL_FLAG: string;
  SET_ERROR_USER_COMMENT: string;
};

export const TagGroupNames: { [key: string]: keyof Tags } = {
  POST_TYPES: 'postTypes',
  KEYWORDS: 'keywords',
  RECOMMENDATIONS: 'recommendations',
  SENTIMENTS: 'sentiments',
  SENTIMENT: 'sentiment',
  INTENTS: 'intents',
  AMPLIFY_ENGAGED: 'amplifyEngaged',
  MESSAGE_STATUS: 'messageStatus',
  CUSTOM_LABEL: 'customLabels',
  MENTIONS: 'isPostMention',
  REPLIES: 'isIndirectComment',
  ASSIGN_STATUS: 'assignedStatus',
  SESSION_START_TYPE: 'sessionStartType',
};

export const notFoundTagGroupName = 'notFound';

export const countInPage = 100;

export default commentsViewerConstants;
export const last30daysIncludingToday = 'last30daysIncludingToday';

export const commentTagFilterMap: {
  [key: string]: string;
} = {
  postTypes: 'postType',
  keywords: 'entities',
  recommendations: 'commentTag', //actual case
  sentiments: 'sentiment.sentimentType',
  sentiment: 'sentiment.rating',
  intents: 'intents',
  isPostMention: 'isPostMention',
  isIndirectComment: 'isIndirectComment',
};

/*eslint-disable*/
export const commentTypes: { id: string; label: string; groupType: keyof Tags; color: string }[] = [
  {
    id: 'top_comments',
    label: 'Top Level',
    groupType: TagGroupNames.REPLIES,
    color: variables.comments,
  },
  {
    id: 'replies',
    label: 'Reply',
    groupType: TagGroupNames.REPLIES,
    color: variables.mentions,
  },
];

export const commentThreadDisabledPlatforms: string[] = []; //left in place if any other platform need to disable comment thread

export const defaultTagGroupNames: string[] = [TagGroupNames.AMPLIFY_ENGAGED, TagGroupNames.REPLIES, TagGroupNames.SENTIMENTS];

export const viewEngagementsHelpText = 'To see user interactions like likes and replies for the comments on your post.';
