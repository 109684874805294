/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/naming-convention */
import { CampaignTypeUI, ResponseActionType } from '../../pages/automation/types';
import { Comment } from '../../pages/dashboard/components/comments-viewer/components/comment/comment.types';
import { KeywordGroups, KeywordGroupToAPI } from '../../pages/keyword-groups/keyword-groups.types';
import { CommentUsers } from '../../pages/dashboard/components/comments-viewer/components/user/user.types';
import { UsersUiType, UserUIType } from '../../pages/user-profile-pages/type';
import { FetchRecommendationsApiResponse } from '../../types/api-calls';
import { Templates } from '../../pages/templates/templates.types';
import { BroadcastTypeUI } from '../../pages/broadcasts/types';
import { conditions, childConditionsIfStatement } from '../components/tagging-gc-content/tagging-gc-content.constants';
import { createdBy, Segments } from '../../pages/segments/segment.types';
import { CommentTags } from '../../pages/common/tag/tag.types';
import { UserIngest } from './types/userIngest.types';
import { Subscriptions } from './types/subscriptions.types';
import { CreatedBy } from '../../pages/admin/sms-deployment/bot-deploy/types';

export type accountConfig = {
  shopify?: {};
  amplifyLiteUIConfig?: AccountLiteUIConfig;
  uiConfig?: UiConfig;
};

export type Account = {
  name: string;
  id: string;
  timezone?: string;
  allows?: {
    [key: string]: boolean;
  };
  admin?: string;
  createdDate?: string;
  lastUpdatedDate?: string;
  createdBy?: createdBy;
  isAmplifyLite?: boolean;
  category?: string;
  config?: accountConfig;
  mfaSettings?: {
    required: boolean;
    requiredFrom: any;
  };
};

export type DefaultSegmentUsersIncludeFields = {
  name: string;
  csvFieldName: string;
};

export type UiConfig = {
  [key: string]: {
    enableDefaultGlobalTargeting?: boolean;
    enableBroadcastCaptureUserInputAndVariables?: boolean;
    enableBroadcastDonationDisplay?: boolean;
    disableSendAsMms?: boolean;
    showNotificationBroadcastOnCM?: boolean;
    enableDeploy?: boolean;
    appendOptOutText?: string;
    previousOptOutText?: string;
    defaultSegmentUsersIncludeFields?: DefaultSegmentUsersIncludeFields[];
    dontShowCarrierFees?: boolean;
  };
};
export type AccountLiteUIConfig = {
  [key: string]: AccountActionConfig | any;
  disabledTaggingGCs?: { [key: string]: boolean };
  configuredKeywords?: KeywordGroupToAPI;
};
export type UserAccount = Account;
export type AccountActionConfig = {
  actions: string[];
};
export type Accounts = {
  [key: string]: Account;
};
export type PlatformBots = {
  [key: string]: PlatformBot;
};
export type PlatformBot = {
  platformBotId: string;
  platform: string;
  botId: string;
  name: string;
  createdDate?: string;
  createdBy?: createdBy;
  id: string;
  instaUsername?: string;
  profilePictureUrl?: string;
  userName?: string;
  deploymentDetails?: {};
  config?: {
    username?: string;
    isInstagramBusinessLoginDeployment?: boolean;
  };
  platformAsInSystemSettings?: string;
  permissions?: string[];
  description?: string;
};
export type Comments = {
  [key: string]: Comment;
};
export type Post = {
  full_picture: string;
  id: string;
  message: string;
  name: string;
  totalComments: number;
  postType: string;
  permalink_url: string;
};
export type PostsForRequest = {
  id: string;
  postType: string;
  totalComments: number;
};
export enum CommentTypeEnumsForIgPosts {
  COMMENTS = 'comments',
  MENTIONS = 'mentions',
}
export type PostsForIgComments = {
  id: string;
  commentType?: CommentTypeEnumsForIgPosts;
  sampleCommentId?: string;
  type?: string;
};
export type PostsForRequestMaped = {
  [key: string]: PostsForRequest;
};
export type Posts = {
  [key: string]: Post;
};
export type BroadcastsUI = {
  [key: string]: BroadcastTypeUI;
};

export type Metrics = {
  metric: string;
  number: number;
};

export type ModuleComponent = {
  type: string;
  [key: string]: any;
};

export type moduleTypeApi = {
  components?: ModuleComponent[];
  description?: string;
  id?: string;
  name?: string;
  lastUpdatedDate?: string;
  versionId?: string;
  [key: string]: any;
};

export type Modules = {
  [key: string]: moduleTypeApi;
};

export type versionTypeApi = {
  modules?: Modules;
  notificationLabels?: any[];
  botId?: string;
  createdDate?: string;
  id?: string;
  lastUpdatedDate?: string;
  name?: string;
};

export type Entities = {
  account?: UserAccount;
  accounts?: Accounts;
  platformBots?: PlatformBots;
  campaigns: {
    [key: string]: CampaignTypeUI;
  };
  variables: VariablesTypeUI;
  customLabels: any;
  sessionStartTypes?: string[];
  comments?: Comments;
  commentUsers?: CommentUsers;
  keywordGroups?: KeywordGroups;
  // campaignTags: CampaignTagsTypeUI;
  taggingGCs?: TaggingGCs;
  users: UsersUiType;
  privateReplyTags?: PrivateReplyTags;
  bots: Bots;
  templates?: Templates;
  archivedTemplates?: Templates;
  platformUsers: PlatformUsersUI;
  posts?: Posts;
  broadcasts: BroadcastsUI;
  broadcastEngagmentSettings?: any;
  segments?: Segments;
  archiveSegments?: Segments;
  variableValues?: { [key: string]: any[] };
  broadcastTime?: string;
  tags?: {
    [key: string]: CommentTags;
  };
  versions?: {
    [key: string]: versionTypeApi;
  };
  userIngests: UserIngest;
  subscriptions: Subscriptions | null;
  segmentTags?: SegmentTags | null;
};
export type RouteParams = { platformBotId?: string };

export type VariableType = {
  name: string;
  description?: string;
  botId?: string | null;
  type: string;
  macro: string;
  createdDate?: string;
  lastUpdatedDate?: string;
  id: string;
  metadata?: any;
  value?: any;
};

export type CreateVariableType = {
  name: string;
  description?: string;
  botId?: string | null;
  type: string;
};

export type VariablesTypeAPI = VariableType[];

export type VariablesTypeUI = {
  [key: string]: VariableType;
};

export type labelType = {
  id: string;
  botId: string;
  name: string;
  description: string;
  lastUpdatedDate?: string;
  createdDate?: string;
  createdBy?: string;
  metadata?: any;
  conditions?: conditions;
};

export type labelTypeAPI = labelType[];

export type labelsTypeUI = {
  [key: string]: labelType | string[] | undefined;
  keywords?: string[];
};

export type CampaignTagType = {
  actions?: {
    type: string;
  }[];
  name: string;
  platformBotId: string;
  platform: string;
  metadata: {
    parentTagId: string;
  };
  createdDate: string;
  lastUpdatedDate?: string;
  id: string;
  allows?: {
    '*': boolean;
  };
};

export type CampaignTagsTypeUI = {
  [key: string]: CampaignTagType;
};
export type TaggingGCs = { [key: string]: TaggingGC };
export type TaggingGC = {
  id: string;
  title: string;
  custom: boolean;
  active: boolean;
  rawGC?: FetchRecommendationsApiResponse[0];
  associatedCampaigns: TaggingGCAssociatedCampaign[];
  conditions?: conditions;
  actions?: ResponseActionType[];
  allows?: Allows;
  createFor?: { [key: string]: boolean };
  description?: string;
};
export type TaggingGCAssociatedCampaign = { id: string; name: string; uiRoute: string; action: string; paused: boolean };

export type PrivateReplyTags = { [key: string]: PrivateReplyTag };
export type PrivateReplyTag = {
  id: string;
  name: string;
  actionType: string;
  createdDate?: string;
  lastUpdatedDate?: string;
  description?: string;
};
export type SuggestedAction = {
  type: string;
};
export type PrivateReplyApiTag = {
  id?: string;
  name: string;
  actions?: SuggestedAction[];
  createdDate?: string;
  lastUpdatedDate?: string;
  description?: string;
};
export type BotSettings = {
  allows: Allows;
  botId: string;
  indexComment?: boolean;
  nlpConfig?: { [key: string]: { messageNLPConfig?: NlpConfig; commentNLPConfig?: NlpConfig; doNotIncludeStandardNlp: boolean; project?: string; model?: string } };
};

export type NlpConfig = {
  configuration: {
    project: string;
  };
  provider: string;
};

export type BotNLP = {
  botId: string;
  currentModel: string;
  trainedDataEntities: { [key: string]: any };
  trained: boolean;
  entities: KeywordGroupToAPI;
  createdDate: string;
  lastUpdatedDate: string;
  id: string;
  allows: Allows;
};

export type Allows = {
  [key: string]: boolean;
};
export type Bot = {
  name: string;
  description: string;
  config: {
    [key: string]: any;
  };
  lastUpdatedDate: string;
  id: string;
  allows: Allows;
  activeVersionId: string;
  prevVersionId: string;
  accountId: string;
  platformBots: PlatformBot[];
  nlp?: BotNLP;
  targetedUsers?: any[];
  platformUsers?: any[];
  testUsers?: any[];
  type?: BotConfigType;
};

export type Bots = {
  [key: string]: Bot;
};
export type AllowsPermissions = { [key: string]: boolean };

export type PlatformUser = {
  serId?: string;

  platform: string;
  platformUserId: string;
  // eg TWitter screenname, IG username etc.
  platformUsername?: string;
  botId: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  gender: string;
  dateOfBirth: string;
  location: Address;
  phoneNumbers: string[];
  emailIds: string[];
  locale: string;
  timezone: number;
  profilePic?: string;
  profilePicId?: string;
  referrals: any;
  blocked?: boolean;
  isPaymentEnabled?: boolean;
  ip?: string;
  invalidIP?: boolean;
  userAgent?: string;
  createdAt: any;
  created?: boolean;
  id: string;
  lastUpdatedDate: string;
  allows: AllowsPermissions;
  messagingStatus?: string;
};
export type Address = {
  line1: string;
  line2: string;
  city: string;
  state: string; // state/region/province, ex: "California"
  stateCode: string; // state/region/province, ex: "CA"
  country: string; // full name, ex: "United States"
  countryCode: string; // 2 digit code, ex: "US"
  postalCode: string; // zip/postal/post code
  geo: {
    lat: number;
    long: number;
  };
};
export type PlatformUserUI = {
  rawUser: PlatformUser;
} & PlatformUser;

export type PlatformUsersUI = {
  [key: string]: PlatformUserUI;
};
export type UsersUpdateOnBot = {
  users: UserUIType[];
  botId: string;
};
export type UpdateTargetingOnBot = {
  users: UserUIType[];
  botId: string;
  targeting: any[];
};
export type DeleteTargeting = {
  targetingId: string;
  botId: string;
};
export type TestUsersUpdateOnBot = {
  testUsers: UserUIType[];
  botId: string;
};
export type nameTypes = {
  firstName?: string;
  lastName?: string;
};

// Generated by https://quicktype.io

export interface BotConfig {
  accessToken: string;
  dndSettings?: DNDSettings;
  chatModeSettings?: ChatModeSettings;
  trackingSettings?: TrackingSettings;
  payment?: Payment;
  webUpdate?: WebUpdate;
  stopWords?: StopWords;
  locale?: string;
  disableProfileLookup?: boolean;
  userLocale?: string;
  whitelistDomain?: string[];
  clickAttribution?: ClickAttribution;
  nlpConfig?: NLPConfig;
  throttling?: Throttling;
  legaleseText?: string;
  indexMessage?: IndexMessage;
  limits?: Limits;
  lastUpdatedDate: string;
  profile?: BotProfile;
  type?: BotConfigType;
  additionalProp1?: string;
  additionalProp2?: string;
  additionalProp3?: string;
  messaging?: BotConfigMessagingConfig;
}
export interface BotConfigMessagingConfig {
  convertMMSToSMSWithLink: boolean;
}
export type BotConfigType = 'cliqz';
export interface ChatModeSettings {
  CUSTOMER_SUPPORT_START: string;
  CUSTOMER_SUPPORT_END: string;
}

export interface ClickAttribution {
  additionalProperties: ClickAttributionAdditionalProperties;
  platformSpecific: PlatformSpecific;
  additionalProp1: PurpleAdditionalProp;
  additionalProp2: PurpleAdditionalProp;
  additionalProp3: PurpleAdditionalProp;
}

export interface PurpleAdditionalProp {}

export interface ClickAttributionAdditionalProperties {
  additionalProperties: PurpleAdditionalProp;
  params: Params;
  broadcast_campaign_param: string;
  click_source_param: string;
  broadcast_params: Params;
}

export interface Params {
  utm_source: string;
  utm_medium: string;
  additionalProperties: string;
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface PlatformSpecific {
  additionalProp1: ClickAttributionAdditionalProperties;
  additionalProp2: ClickAttributionAdditionalProperties;
  additionalProp3: ClickAttributionAdditionalProperties;
}

export interface DNDSettings {
  DND_DISABLED: boolean;
  DND_MODE: string;
  DND_START: number;
  DND_END: number;
}

export interface IndexMessage {
  enabled: boolean;
  disableBlockDetection: boolean;
  disabledUnsubscribeDetection: boolean;
  indexAllMessagesFromUser: boolean;
  enableAssignMe?: boolean;
}

export interface Limits {
  freeTierMessageLimit: number;
}

export interface NLPConfig {
  default: NLPConfigAdditionalProp;
  additionalProp1: NLPConfigAdditionalProp;
  additionalProp2: NLPConfigAdditionalProp;
  additionalProp3: NLPConfigAdditionalProp;
}

export interface NLPConfigAdditionalProp {
  messageNLPConfig: string;
  commentNLPConfig: string;
  doNotIncludeStandardNlp: boolean;
  baseBotNlpDataId: string;
}

export interface Payment {
  currency: Currency;
  merchantInfo: MerchantInfo;
  shippingInfo: ShippingInfo;
  methodData: MethodData;
  platform: PaymentPlatform;
  keys: Keys;
}

export interface Currency {
  name: string;
  sign: string;
}

export interface Keys {
  stripe: Razorpay;
  razorpay: Razorpay;
  facebook: KeysFacebook;
}

export interface KeysFacebook {
  public_key: string;
}

export interface Razorpay {
  public: string;
  secret: string;
}

export interface MerchantInfo {
  title: string;
  imageUrl: string;
  confirmationText: string;
  termsUrl: string;
  privacyPolicyUrl: string;
  platform: MerchantInfoPlatform;
}

export interface MerchantInfoPlatform {
  facebook: string;
  slack: string;
}

export interface MethodData {
  supportedMethods: string;
  data: string;
}

export interface PaymentPlatform {
  facebook: PlatformFacebook;
  slack: string;
}

export interface PlatformFacebook {
  is_test_payment: boolean;
  payment_type: string;
  requested_user_info: string[];
}

export interface ShippingInfo {
  id: string;
  title: string;
  availability: Availability;
  amount: number;
  default: boolean;
}

export interface Availability {
  blacklist: List[];
  whitelist: List[];
}

export interface List {
  country: string;
  zipcodes: string[];
  states: string[];
}

export interface BotProfile {
  name?: string;
  userName?: string;
  profilePic?: string;
  primaryPhoneNumber?: string;
  popupUrl?: string;
  popupId?: string;
  completeName?: nameTypes;
}

export interface StopWords {
  enabled: boolean;
  words: string[];
}

export interface Throttling {
  maxMessageAgeMins: MaxMessageAgeMins;
  default: ThrottlingAdditionalProp1;
  additionalProp1: ThrottlingAdditionalProp1;
  additionalProp2: ThrottlingAdditionalProp1;
  additionalProp3: ThrottlingAdditionalProp1;
}

export interface ThrottlingAdditionalProp1 {
  schedulerMessagesPerSecondPerJob: number;
  botEngineMessagesPerSecond: number;
  botEngineMMSPerSecond: number;
}

export interface MaxMessageAgeMins {
  additionalProp1: number;
  additionalProp2: number;
  additionalProp3: number;
}

export interface TrackingSettings {
  buttonClickTracking: boolean;
  imageClickTracking: boolean;
}

export interface WebUpdate {
  defaultImage: string;
}

export type CreateAuditLogAPIRequest = {
  entityType: string;
  botId: string;
  doc: any;
};

export type verifyMfaOtpResponse = {
  success?: boolean;
  error?: string;
  access_token?: string;
  mfa?: {
    required: boolean;
    enabled: boolean;
  };
  qrCodeUri?: string;
};

export type SegmentTagGroupCondition = {
  id: string;
  notCondition: boolean;
  segmentId: string;
  tagId: string;
  tagGroupName: string;
  ifStatements: childConditionsIfStatement;
  logicalOperator: string;
  childConditions: any[];
  goToModules: string[];
  storeVariable: any[];
  onMatchPlugins: any[];
};

export type SegmentTagGroup = {
  name: string;
  percentage: number;
  conditions: SegmentTagGroupCondition;
  lastUpdatedDate: string;
  description?: string;
};

export type SegmentTagAPI = {
  id: string;
  botId: string;
  prefix: string;
  seed: string;
  updatedTimestamp: number;
  groups: SegmentTagGroup[];
  lastUpdatedDate: string;
  createdDate: string;
  createdBy: CreatedBy;
  updatedBy: CreatedBy;
  users?: any;
  description?: string;
};
export type SegmentTags = {
  [key: string]: SegmentTagUI;
};

export type SegmentTagUsers = {
  name: string;
  percentage: number;
  userCount: number;
  tagName: string;
};

export type SegmentTagUI = SegmentTagAPI & {
  users?: SegmentTagUsers[];
};

export type SegmentUser = {
  id: string;
  users: SegmentTagUsers[];
};
