import moment from 'moment-timezone';
import { Comments, Post, Posts, PlatformBot } from '../../../../../common/entities/entities.types';
import { Comment, CommentActions, ReplyObjAPI, ReplyInfo } from '../components/comment/comment.types';
import {
  ActionKeyToText,
  ActionKeyToType,
  colorForPostType,
  colorForSentiment,
  colorForSentimentScore,
  getAmplifyEngagementTypeNames,
  getCommentOrReplyLink,
} from '../components/comment/utils';
import { SearchFilterTag, Tags } from '../../../../common/tag/tag.types';
import { timeFilterOptions, customRange } from '../../../../common/time-filter/time-filter.constants';
import { CommentUser, CommentUsers } from '../components/user/user.types';
import {
  commentTagFilterMap,
  commentTypes,
  commentViewerTabs,
  defaultTagGroupNames,
  last30daysIncludingToday,
  notFoundTagGroupName,
  TagGroupNames,
  twitterPrivateReplyURL,
} from '../comments-viewer.constants';
import variables from '../../../../../common/scss/variables.module.scss';
import { filterUsingSearchFilterTags } from '../../../../../common/utils';
import { Template } from '../../../../templates/templates.types';
import { ResponseActionType } from '../../../../automation/types';
import { EntityWithTags, RootState } from '../../../../../types';
// import { MediaTypes } from '../../../../../common/entities/entities.constants';
import { messageStatusToIcon } from '../../../../inbox/config';
// import { engagementActions } from '../components/comment/comment.constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComments } from '../../../../../common/entities/actions/async-actions';
import { TimeFilterParams } from '../../../../common/time-filter/time-filter.types';
import { decodeString, encodeValue } from '../../../../../common/utils/persist-time-filter';
import { getTimeZone } from '../../../../../common/utils/time-zones';
import { capitalizeFirstLetter } from '../../../../../common/entities/utils';
import { sessionStartTypeMap } from '../../../../inbox/constants';
import { waTemplateStatus } from '../../../../broadcasts/constants';
import { getAmplifyEngagedTypeLabel } from '../../../../../common/entities/transformer/fetch-comments';
import { postType } from '../../../../../common/entities/entities.constants';
// import { setSearchFilterTags } from '../components/search-filter/actions';

/* eslint-disable max-lines*/
export const getStartAndEndDate = (startDate: string | undefined, endDate: string | undefined, dateFilter: string, timezone: string = '') => {
  timezone = getTimeZone(timezone);
  //check if startDate/endDate is missing/invalid
  if (!startDate || !endDate || !moment(startDate).toISOString() || !moment(endDate).toISOString()) {
    const getDates = getStartAndEndDateFromFilter(dateFilter, timezone);
    startDate = getDates.startDate;
    endDate = getDates.endDate;
  } else {
    startDate = moment(startDate).tz(timezone).format('YYYYMMDD');
    endDate = moment(endDate).tz(timezone).format('YYYYMMDD');
  }
  return { startDate, endDate };
};

export const getStartAndEndDateFromFilter = (dateFilter: string, timeZone?: string) => {
  let startDate = '';
  let endDate = '';
  timeZone = timeZone ? getTimeZone(timeZone) : timeZone;
  const momentChainStart = timeZone ? moment().tz(timeZone) : moment();
  const momentChainEnd = timeZone ? moment().tz(timeZone) : moment();

  switch (dateFilter) {
    case timeFilterOptions[0].value:
      startDate = momentChainStart.subtract(0, 'days').format('YYYYMMDD');
      endDate = momentChainEnd.subtract(0, 'days').format('YYYYMMDD');
      break;
    case timeFilterOptions[1].value:
      startDate = momentChainStart.subtract(1, 'days').format('YYYYMMDD');
      endDate = momentChainEnd.subtract(1, 'days').format('YYYYMMDD');
      break;
    case timeFilterOptions[2].value:
      startDate = momentChainStart.subtract(7, 'days').format('YYYYMMDD');
      endDate = momentChainEnd.subtract(1, 'days').format('YYYYMMDD');
      break;
    case timeFilterOptions[3].value:
      startDate = momentChainStart.subtract(14, 'days').format('YYYYMMDD');
      endDate = momentChainEnd.subtract(1, 'days').format('YYYYMMDD');
      break;
    case timeFilterOptions[4].value:
      startDate = momentChainStart.subtract(30, 'days').format('YYYYMMDD');
      endDate = momentChainEnd.subtract(1, 'days').format('YYYYMMDD');
      break;
    case customRange:
      startDate = '';
      endDate = '';
      break;
    case last30daysIncludingToday:
      startDate = momentChainStart.subtract(30, 'days').format('YYYYMMDD');
      endDate = momentChainEnd.subtract(0, 'days').format('YYYYMMDD');
      break;
    default:
      startDate = momentChainStart.subtract(7, 'days').format('YYYYMMDD');
      endDate = momentChainEnd.subtract(1, 'days').format('YYYYMMDD');
  }
  return { startDate, endDate };
};

export const getTotalComments = (comments: Comments) => {
  const totalComments: Comments = { ...comments };
  return totalComments;
};

export const getTotalEngaged = (comments: Comments) => {
  const totalEngaged: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (hasEngagedAction(comment.actions)) {
      totalEngaged[comment.commentId] = comment;
    }
  });
  return totalEngaged;
};

//u can use this hook if you need to fetchcommets on the done click on filter modal, but for now we are doing with hook
export const useOnFilterComments = (params: TimeFilterParams, posts: any, filterBy: string) => {
  const dispatch = useDispatch();
  const onFilter = (appliedTag: Tags, customSearchText?: string) => {
    dispatch(fetchComments(params, { ...appliedTag, customText: customSearchText }, posts, filterBy));
  };
  return onFilter;
};

// export const useSelectedTagsForComments = () => {
//   const dispatch = useDispatch();
//   const selectedTags = useSelector((state: RootState) => state?.commentsViewer?.searchFilter?.selectedSearchFilterTags);
//   const appliedCustomFilter = useSelector((state: RootState) => state?.commentsViewer?.searchFilter?.appliedCustomSearchText);
//   const setSelectedTags = (selectedTags: SearchFilterTag[]) => {
//     dispatch(setSearchFilterTags(commentsViewerConstants.COMMENTS_VIEWER)(selectedTags));
//   };
//   return { selectedTags, setSelectedTags, appliedCustomFilter };
// };

export const useSearchFilterStateForComments = () => {
  return useSelector((state: RootState) => state?.commentsViewer?.searchFilter);
};

export const getTotalEngagements = (comments: Comments) => {
  const totalEngagements: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (hasEngagedAction(comment.actions)) {
      totalEngagements[comment.commentId] = comment;
    }
  });
  return totalEngagements;
};

export const getActionableComments = (comments: Comments) => {
  const actionableComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (!hasEngagedAction(comment.actions) && Object.keys(comment.tags.recommendations).length) {
      actionableComments[comment.commentId] = comment;
    }
  });
  return actionableComments;
};

export const getNonActionableComments = (comments: Comments) => {
  const nonActionableComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (!hasEngagedAction(comment.actions) && !Object.keys(comment.tags.recommendations).length) {
      nonActionableComments[comment.commentId] = comment;
    }
  });
  return nonActionableComments;
};

export const getNonActionableLabeledComments = (comments: Comments) => {
  const nonActionableLabeledComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (
      !hasEngagedAction(comment.actions) &&
      !Object.keys(comment.tags.recommendations).length &&
      (Object.keys(comment.tags.intents).length || Object.keys(comment.tags.keywords).length)
    ) {
      nonActionableLabeledComments[comment.commentId] = comment;
    }
  });
  return nonActionableLabeledComments;
};

export const getNonActionableUnlabeledComments = (comments: Comments) => {
  const nonActionableUnlabeledComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (
      !hasEngagedAction(comment.actions) &&
      !Object.keys(comment.tags.recommendations).length &&
      !(Object.keys(comment.tags.intents).length || Object.keys(comment.tags.keywords).length)
    ) {
      nonActionableUnlabeledComments[comment.commentId] = comment;
    }
  });
  return nonActionableUnlabeledComments;
};

export const getAssignedCommentsToCurrentUser = (comments: Comments, userId?: string) => {
  const currentUserAssignedComments: Comments = {};
  const defaultMinDate = moment('1970-01-01');
  Object.values(comments).forEach((comment: Comment) => {
    const actions = comment.actions;
    if (actions.assign?.assignedUser?.id === (userId || '')) {
      /*check if any action other has assign is done after assignment time*/
      if (
        moment.max([
          defaultMinDate,
          ...Object.entries(actions)
            .filter(([actionType, action]: [string, any]) => action && actionType !== 'assign')
            .map(([_actionType, action]: [string, any]) =>
              action.replies
                ? action.replies?.date
                  ? moment(action.replies?.date)
                  : moment.max([defaultMinDate, ...(action.replies || []).map((reply: ReplyInfo) => moment(reply.date))])
                : moment(action.date),
            ),
        ]) < moment(actions.assign?.date)
      ) {
        currentUserAssignedComments[comment.commentId] = comment;
      }
    }
  });
  return currentUserAssignedComments;
};

export const getAllAssignedComments = (comments: Comments) => {
  const allAssignedComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (comment.actions.assign) {
      allAssignedComments[comment.commentId] = comment;
    }
  });
  return allAssignedComments;
};

const hasEngagedAction = (actions: CommentActions, excludeAssign?: boolean) => {
  return actions.automatedHide ||
    actions.hide ||
    actions.externalHide ||
    actions.automatedBlock ||
    actions.externalBlock ||
    (actions.block && !actions.block?.automatic) ||
    actions.externalLike ||
    actions.automatedLike ||
    actions.like ||
    actions.privateReply ||
    actions.automatedPrivateReply ||
    actions.automatedPublicReply ||
    actions.externalPublicReply ||
    actions.publicReply ||
    actions.externalDelete ||
    actions.automatedDelete ||
    actions.delete ||
    actions.review ||
    actions.automatedReview ||
    (!excludeAssign && actions.assign)
    ? /* ||
    actions.externalUserCommentDelete */
      true
    : false;
};

// const isEngagedAction = (action: string) => {
//   return Object.values(engagementActions).includes(action) || action === 'externalBlock';
// };

export const getHiddenComments = (comments: Comments) => {
  const hiddenComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (comment.actions.automatedHide || comment.actions.hide || comment.actions.externalHide) {
      hiddenComments[comment.commentId] = comment;
    }
  });
  return hiddenComments;
};

export const getBrandReactions = (comments: Comments) => {
  const brandReactions: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (comment.actions.externalLike || comment.actions.automatedLike || comment.actions.like) {
      brandReactions[comment.commentId] = comment;
    }
  });
  return brandReactions;
};

export const getDeletedComments = (comments: Comments) => {
  const deletedComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (comment.actions.externalDelete || comment.actions.automatedDelete || comment.actions.delete /* || comment.actions.externalUserCommentDelete */) {
      deletedComments[comment.commentId] = comment;
    }
  });
  return deletedComments;
};

export const getPublicReplies = (comments: Comments) => {
  const publicReplies: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (comment.actions.externalPublicReply || comment.actions.publicReply || comment.actions.automatedPublicReply) {
      publicReplies[comment.commentId] = comment;
    }
  });
  return publicReplies;
};

export const getPublicReplyCount = (publicReplyComments: EntityWithTags[] = []) => {
  let count = 0;
  publicReplyComments.forEach((comment: EntityWithTags) => {
    const actions = comment.actions as { [key: string]: ReplyObjAPI };

    [actions.externalPublicReply, actions.publicReply, actions.automatedPublicReply].forEach((replyObject: ReplyObjAPI) => {
      if (!replyObject) {
        return;
      }
      if (replyObject && replyObject.replies) {
        count += replyObject.replies.length;
      } else {
        count++;
      }
    });
  });

  return count;
};

export const getPrivateReplies = (comments: Comments) => {
  const privateReplies: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (comment.actions.privateReply || comment.actions.automatedPrivateReply) {
      privateReplies[comment.commentId] = comment;
    }
  });
  return privateReplies;
};

export const getReviewedComments = (comments: Comments) => {
  const reviewedComments: Comments = {};
  Object.values(comments).forEach((comment: Comment) => {
    if (comment.actions.review || comment.actions.automatedReview) {
      reviewedComments[comment.commentId] = comment;
    }
  });
  return reviewedComments;
};

export const getBlockedUsers = (users: CommentUsers) => {
  const blockedUsers: CommentUsers = {};
  let count = 0;
  Object.values(users).forEach((user: CommentUser) => {
    if (user.blocked) {
      blockedUsers[user.userId] = user;
      count++;
    }
  });
  return { blockedUsers, count };
};

export const getABlockedCommentByUser = (comments: Comments, user: CommentUser): Comment | undefined => comments[user.commentIds[0]];

export const getPrimaryTabName = (tabName: string) => {
  switch (tabName) {
    case commentViewerTabs.hiddenComments:
    case commentViewerTabs.blockedUsers:
    case commentViewerTabs.brandReaction:
    case commentViewerTabs.publicReplies:
    case commentViewerTabs.deletedComments:
    case commentViewerTabs.reviewedComments:
    case commentViewerTabs.engagements:
      return tabName;
    case commentViewerTabs.labeled:
    case commentViewerTabs.unlabeled:
      return commentViewerTabs.nonActionable;
    default:
      return tabName;
  }
};

export const orderComments = (comments: Comment[]) => {
  const normalComments: Comment[] = [];
  const commentsDeletedByUser: Comment[] = [];
  const blockedUserComments: Comment[] = [];
  comments.forEach((comment: Comment) => {
    if (comment.actions.externalUserCommentDelete) commentsDeletedByUser.push(comment);
    else if (comment.actions.block || comment.actions.automatedBlock) blockedUserComments.push(comment);
    else normalComments.push(comment);
  });
  return [...normalComments, ...commentsDeletedByUser, ...blockedUserComments];
};
export const getSentimentRating = (sentimentScore: number) => {
  if (sentimentScore > 10) {
    return sentimentLabel.EXTREMELY_POSITIVE;
  } else if (sentimentScore >= 3 && sentimentScore <= 10) {
    return sentimentLabel.VERY_POSITIVE;
  } else if (sentimentScore > 0 && sentimentScore < 3) {
    return sentimentLabel.MODERATELY_POSITIVE;
  } else if (sentimentScore === 0) {
    return sentimentLabel.NEUTRAL;
  } else if (sentimentScore < 0 && sentimentScore > -3) {
    return sentimentLabel.MODERATELY_NEGATIVE;
  } else if (sentimentScore <= -3 && sentimentScore >= -10) {
    return sentimentLabel.VERY_NEGATIVE;
  } else if (sentimentScore < -10) {
    return sentimentLabel.EXTREMELY_NEGATIVE;
  }
};

export const sentimentLabel: { [key: string]: string } = {
  EXTREMELY_POSITIVE: 'Extremely Positive',
  VERY_POSITIVE: 'Very Positive',
  MODERATELY_POSITIVE: 'Moderately Positive',
  NEUTRAL: 'Neutral',
  MODERATELY_NEGATIVE: 'Moderately Negative',
  VERY_NEGATIVE: 'Very Negative',
  EXTREMELY_NEGATIVE: 'Extremely Negative',
};

export const sentimentRatingLabel: { [key: string]: string } = {
  NEGATIVE: 'Negative',
  NEUTRAL: 'Neutral',
  POSITIVE: 'Positive',
  MIXED: 'Mixed',
};

export const findTagInAllTags = (tags: Tags, { groupType, id, label, color }: SearchFilterTag): SearchFilterTag => {
  let foundTag = { groupType, id, label, color };
  Object.keys(tags).some((tagGroupName: string) => {
    if (tags[tagGroupName as keyof typeof tags]?.[id]) {
      foundTag = tags[tagGroupName as keyof typeof tags]?.[id]!;
      return true;
    }
    return false;
  });
  return foundTag;
};

//eslint-disable-next-line max-lines-per-function
export const getTagFromIdAndGroup = (id: string, groupType: string, color?: string, label?: string): SearchFilterTag => {
  switch (groupType) {
    case TagGroupNames.SENTIMENTS:
      return {
        color: colorForSentimentScore(id),
        label: id,
        id,
        groupType: TagGroupNames.SENTIMENTS,
      };
    case TagGroupNames.SENTIMENT:
      return {
        color: colorForSentiment(id),
        label: id,
        id,
        groupType: TagGroupNames.SENTIMENT,
      };
    case TagGroupNames.INTENTS:
      return {
        color: variables.intent,
        id,
        label: (id && id.split('__')[1]) || id,
        groupType: TagGroupNames.INTENTS,
      };
    case TagGroupNames.KEYWORDS:
      return {
        color: variables.entity,
        id,
        label: id,
        groupType: TagGroupNames.KEYWORDS,
      };
    case TagGroupNames.MESSAGE_STATUS:
      return {
        color: variables.messageStatus,
        id,
        label: id,
        groupType: TagGroupNames.MESSAGE_STATUS,
        iconSrc: (messageStatusToIcon as any)[id],
      };
    case TagGroupNames.ASSIGN_STATUS:
      return {
        color: variables.mentions,
        id,
        label: `All ${capitalizeFirstLetter(id)}`,
        groupType: TagGroupNames.ASSIGN_STATUS,
      };
    case TagGroupNames.POST_TYPES:
      return {
        color: colorForPostType(id),
        id,
        label: capitalizeFirstLetter(id),
        groupType: TagGroupNames.POST_TYPES,
        iconSrc: (messageStatusToIcon as any)[id],
      };
    case TagGroupNames.RECOMMENDATIONS:
      return {
        color: variables.recommendationDefault,
        id,
        label: id,
        groupType: TagGroupNames.RECOMMENDATIONS,
      };
    case TagGroupNames.MENTIONS:
      return {
        color: variables.mentions,
        id: String(id),
        label: Number(id) === 1 ? 'Post Mention' : 'Comment Mention',
        groupType: TagGroupNames.MENTIONS,
      };
    case TagGroupNames.REPLIES:
      const commentType = commentTypes.find((type: any) => type.id === id) || commentTypes[0];
      return commentType;
    case TagGroupNames.SESSION_START_TYPE:
      return {
        color: variables.sessionStartType,
        id,
        label: sessionStartTypeMap[id] || id,
        groupType: TagGroupNames.SESSION_START_TYPE,
      };
    case TagGroupNames.AMPLIFY_ENGAGED:
      return {
        color: color || variables.recommendationDefault,
        id,
        label: label || id,
        groupType: TagGroupNames.AMPLIFY_ENGAGED,
      };
    default:
      return { color: variables.recommendationDefault, id, label: id, groupType: notFoundTagGroupName };
  }
};

export const checkCommentsCountOnApplyingTag = (comments: Comments | undefined, appliedTags: Tags) => {
  const totalComments = comments ? Object.values(getTotalComments(comments)) : [];
  return totalComments.length && filterUsingSearchFilterTags(totalComments, appliedTags).length;
};
export const checkEngagementsCountOnApplyingTag = (comments: Comments | undefined, commentUsers: CommentUsers | undefined, appliedTags: Tags) => {
  const hiddenComments = comments ? Object.values(getHiddenComments(comments)) : [];
  const brandReactions = comments ? Object.values(getBrandReactions(comments)) : [];
  const publicReplies = comments ? Object.values(getPublicReplies(comments)) : [];
  const privateReplies = comments ? Object.values(getPrivateReplies(comments)) : [];
  const deletedComments = comments ? Object.values(getDeletedComments(comments)) : [];
  const blockedUsers = commentUsers ? Object.values(getBlockedUsers(commentUsers).blockedUsers) : [];
  const reviewedComments = comments ? Object.values(getReviewedComments(comments)) : [];

  const updatedHiddenCommentsCount = hiddenComments.length && filterUsingSearchFilterTags(hiddenComments, appliedTags).length;
  const updatedBrandReactionsCount = brandReactions.length && filterUsingSearchFilterTags(brandReactions, appliedTags).length;
  const updatedPublicRepliesCount = publicReplies.length && filterUsingSearchFilterTags(publicReplies, appliedTags).length;
  const updatedPrivateRepliesCount = privateReplies.length && filterUsingSearchFilterTags(privateReplies, appliedTags).length;
  const updatedDeletedCommentsCount = deletedComments.length && filterUsingSearchFilterTags(deletedComments, appliedTags).length;
  const updatedBlockedUsersCount = blockedUsers.length && filterUsingSearchFilterTags(blockedUsers, appliedTags).length;
  const updatedReviewedCommentsCount = reviewedComments.length && filterUsingSearchFilterTags(reviewedComments, appliedTags).length;

  return (
    updatedHiddenCommentsCount +
    updatedBrandReactionsCount +
    updatedPublicRepliesCount +
    updatedBlockedUsersCount +
    updatedDeletedCommentsCount +
    updatedPrivateRepliesCount +
    updatedReviewedCommentsCount
  );
};
export const getTemplate = (template: Template) => {
  return { ...template, sendPrivateReplyButton: false, privateReplyButtonContent: '', messageToAPI: template.message };
};
const getTransformedTemplate = (template: Template) => {
  const transformedTemplate: Template = getTemplate(template);
  transformedTemplate.mediaName = template.mediaName;
  transformedTemplate.mediaUrl = template.mediaUrl;
  if (template.message.includes(twitterPrivateReplyURL)) {
    const length = template.message.length;
    transformedTemplate.sendPrivateReplyButton = true;
    transformedTemplate.privateReplyButtonContent = template.message.substr(template.message.indexOf(twitterPrivateReplyURL), length);
    transformedTemplate.message = template.message.substr(0, template.message.indexOf(twitterPrivateReplyURL) - 2);
    if (transformedTemplate.privateReplyButtonContent.includes('&text=')) {
      transformedTemplate.privateReplyButtonContent = transformedTemplate.privateReplyButtonContent.substr(
        transformedTemplate.privateReplyButtonContent.indexOf('&text=') + 6,
        transformedTemplate.privateReplyButtonContent.length,
      );
      transformedTemplate.privateReplyButtonContent = decodeURI(transformedTemplate.privateReplyButtonContent);
    } else {
      transformedTemplate.privateReplyButtonContent = '';
    }
  }
  return transformedTemplate;
};
export const getPrivateReplyDataInTemplate = (allTemplates: Template[]): any => {
  const templates = allTemplates.map((template: Template) => {
    return getTransformedTemplate(template);
  });
  const standardTemplates: Template[] = [];
  const customTemplates: Template[] = [];
  Object.values(templates).forEach((t: Template) => (t.custom ? customTemplates.push(t) : standardTemplates.push(t)));
  return { standardTemplates, customTemplates };
};
export const getInitialTemplate = (template: Template): any => {
  return getTransformedTemplate(template);
};
export const getCampaignData = (campaignData: ResponseActionType) => {
  let includePrivateReply = false;
  let publicReplyContent = campaignData.replyMessage;
  let privateReplyContent = '';
  const attachmentUrl = campaignData.replyGIFUrl ? campaignData.replyGIFUrl : campaignData.replyImageUrl;
  const attachmentType = attachmentUrl?.substring(attachmentUrl?.lastIndexOf('.') + 1 || 0) || '';
  if (campaignData.replyMessage?.includes(twitterPrivateReplyURL)) {
    const length = campaignData.replyMessage?.length;
    includePrivateReply = true;
    privateReplyContent = campaignData.replyMessage?.substr(campaignData.replyMessage?.indexOf(twitterPrivateReplyURL), length);
    publicReplyContent = campaignData.replyMessage?.substr(0, campaignData.replyMessage?.indexOf(twitterPrivateReplyURL) - 2);
    if (privateReplyContent?.includes('&text=')) {
      privateReplyContent = privateReplyContent.substr(privateReplyContent.indexOf('&text=') + 6, privateReplyContent.length);
      privateReplyContent = decodeURI(privateReplyContent);
    } else {
      privateReplyContent = '';
    }
  }
  return { publicReplyContent, privateReplyContent, includePrivateReply, attachmentType, attachmentUrl };
};

export const getReplyMessage = (includePrivateReplyButton: boolean, previewMessageText: string, platformBotId: string, previewButtonText: string): string => {
  return (
    (includePrivateReplyButton ? previewMessageText + `  ${twitterPrivateReplyURL}${platformBotId}` : previewMessageText) +
    (includePrivateReplyButton && previewButtonText ? `&text=${encodeURI(previewButtonText)}` : '')
  );
};
export const getEditTemplate = (template: Template): Template => {
  const transformedTemplate: Template = getTemplate(template);
  transformedTemplate.mediaName = template.mediaName;
  transformedTemplate.mediaUrl = template.mediaUrl;
  if (template.message.includes(twitterPrivateReplyURL)) {
    const length = template.message.length;
    transformedTemplate.sendPrivateReplyButton = true;
    transformedTemplate.privateReplyButtonContent = template.message.substr(template.message.indexOf(twitterPrivateReplyURL), length);
    transformedTemplate.message = template.message.substr(0, template.message.indexOf(twitterPrivateReplyURL) - 2);
    if (transformedTemplate.privateReplyButtonContent.includes('&text=')) {
      transformedTemplate.privateReplyButtonContent = transformedTemplate.privateReplyButtonContent.substr(
        transformedTemplate.privateReplyButtonContent.indexOf('&text=') + 6,
        transformedTemplate.privateReplyButtonContent.length,
      );
      transformedTemplate.privateReplyButtonContent = decodeURI(transformedTemplate.privateReplyButtonContent);
    } else {
      transformedTemplate.privateReplyButtonContent = '';
    }
  }
  return transformedTemplate;
};
export const getCommentInfoReplyMessage = (message: string) => {
  if (message.includes(twitterPrivateReplyURL)) {
    return message.substr(0, message.indexOf(twitterPrivateReplyURL) - 2);
  }
  return message;
};

export const getUpdatedFilterString = (key: string, value: any, filters?: string) => {
  const url = new URL(window.location.href);
  const params = filters ? filters : url.searchParams.get('filters');
  const urlParams: any = decodeString(params || '');
  const newUrlParams = {
    ...urlParams,
    [key]: value,
  };
  return encodeValue(newUrlParams);
};

export const getFiltersForComments = (filters: any) => {
  const filtersFromURL: any = {};
  const tags = filters?.tag || [];
  const tagFilterList = [...Object.keys(commentTagFilterMap), 'amplifyEngaged'];
  tagFilterList.forEach((key: string) => {
    filtersFromURL[key] = tags.filter((tag: any) => tag.groupType === key).reduce((value: any, item: any) => ({ ...value, [item.id]: item }), {});
  });
  filtersFromURL.customText = filters?.customFilter;
  return filtersFromURL;
};

export const getFiltersFromState = (filtersState: any) => ({
  keywords: filtersState.appliedSearchFilterTagsKeywords || {},
  intents: filtersState.appliedSearchFilterTagsIntents || {},
  sentiments: filtersState.appliedSearchFilterTagsSentiments || {},
  sentiment: filtersState.appliedSearchFilterTagsSentiment || {},
  recommendations: filtersState.appliedSearchFilterTagsRecommendations || {},
  postTypes: filtersState.appliedSearchFilterTagsPostTypes || {},
  amplifyEngaged: filtersState.appliedSearchFilterTagsAmplifyEngaged || {},
  customText: filtersState.appliedCustomSearchText,
  isPostMention: filtersState.appliedSearchFilterTagsPostMentions || {},
  isIndirectComment: filtersState.appliedSearchFilterTagsForReplyFilters || {},
});

export const filterCommentsWithPosts = (comments: Comment[], posts: Post[] = []) => {
  // REMOVE COMMENTs TO ENABLE API SIDE FILTERING
  // const indicateCommentMessage = useSelector((state: RootState) => state.commentsViewer?.comments?.indicateRecentCommentMessage);
  // const filterCommentsFromAPIForPosts = useSelector((state: RootState) => state.pageUIState.filterCommentsFromAPIForPosts);
  if (posts.length > 0 && comments.length > 0) {
    // if (!indicateCommentMessage && !filterCommentsFromAPIForPosts) {
    // if (!indicateCommentMessage && !filterCommentsFromAPIForPosts) {
    const postIdList = posts.map((post: Post) => post.id);
    return comments.filter((comment: Comment) => postIdList.includes(comment?.userComment.postId));
    // }
  }
  return comments;
};
export const convertToDropDownOptions = (list?: any[], propertyName: string = '', formatter?: (obj: any) => string) => {
  if (!list?.length) {
    return [];
  }
  const allOptions: any[] =
    (propertyName &&
      list?.map((listObj: any) => {
        return { label: formatter ? formatter(listObj) : listObj?.[propertyName], value: listObj?.id };
      })) ||
    [];
  return allOptions;
};
export const replaceArrayAtIndex = (array?: any[], index: any = null, newValue: string | null = null) => {
  if (index === null || index < 0 || index > (array || []).length) {
    // throw new Error('Index is out of bounds');
    return array;
  }

  const newArray = [...(array || [])]; // Create a shallow copy of the original array
  newArray[index] = newValue?.trim(); // Replace the element at the specified index

  return newArray; // Return the modified copy
};
export const extractSelectedWaTemplateInBroadcast = (selectedBroadcast?: any, waTemplates?: any, setInStateCallback?: Function) => {
  const { value, id: selectedTemplateId } = selectedBroadcast?.responses[0]?.components?.[0] || {};
  const templateSelected = JSON.parse(JSON.stringify({ ...waTemplates?.[selectedTemplateId] }));
  if (value?.message) {
    value.message.gallery && templateSelected?.message && (templateSelected.message.gallery = { ...value?.message?.gallery });
    templateSelected.message = { ...templateSelected.message, ...value?.message };
  }
  setInStateCallback?.({ ...templateSelected, ...(!templateSelected?.id ? { name: value?.name, id: value?.name, status: waTemplateStatus.REJECTED } : {}) });

  return templateSelected;
};
export const removeExtraWhiteSpace = (str?: string) => {
  return str?.replace(/\s+/g, ' ').trim();
};

export const getCommentsExportData = (comments: Comment[], posts?: Posts, platformBotObj?: PlatformBot) => {
  let max = 0;
  const data = comments.map((comment: Comment) => {
    const names = comment.senderName?.split(' ');
    const firstName = names?.[0] ? names?.[0] : '';
    const lastName = names?.length > 1 ? names?.[names?.length - 1] : '';
    const platformUserName = comment.userComment.platformUsername || '';
    let result = {
      comment: comment.text || '',
      platformUserId: comment.platformUserId || '',
      firstName: firstName === platformUserName ? '' : firstName,
      lastName,
      platform: comment.userComment?.platform || '',
      timestamp: new Date(comment.createdTime)?.getTime() || '',
      commentType: comment.tags?.isIndirectComment?.top_comments?.label || comment?.tags?.isIndirectComment?.replies?.label || '',
      postId: comment.userComment?.postId || '',
      postType: comment.userComment?.postType || '',
      postUrl: posts?.[comment.userComment?.postId]?.permalink_url || '',
      keywords: Object.keys(comment.tags?.keywords || {}).length > 0 ? `'${Object.keys(comment.tags?.keywords).join(',')}'` : '',
      sentiments: Object.keys(comment.tags?.sentiments || {}).length > 0 ? `'${Object.keys(comment.tags?.sentiments).join(',')}'` : '',
      intents: Object.keys(comment.tags?.intents || {}).length > 0 ? `'${Object.keys(comment.tags?.intents).join(',')}'` : '',
      commentUrl: getCommentOrReplyLink(platformBotObj, comment),
      platformUserName,
      commentTag: comment.userComment?.commentTag || '',
    };
    const actionKeys = Object.keys(comment.actions || {}) as (keyof CommentActions)[];
    max = Math.max(max, actionKeys.length);
    actionKeys.forEach((actionType: keyof CommentActions, index: number) => {
      const seq = index + 1;
      const actionKey = 'Engagement ' + seq;
      const sourceKey = 'Engagement Source ' + seq;
      const userKey = 'Engagement User ' + seq;
      const user = comment.actions?.[actionType] as any;
      result = {
        ...result,
        [actionKey]: ActionKeyToText[actionType] || actionType,
        [sourceKey]: ActionKeyToType[actionType] || '',
        [userKey]: user?.user || '',
      };
    });
    return result;
  });
  return { data, maxActions: max };
};

export const getCommentExportHeaders = (maxActions: number) => {
  const result = [
    { label: 'Comment', key: 'comment' },
    { label: 'Platform User ID', key: 'platformUserId' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Platform', key: 'platform' },
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'Platform Username', key: 'platformUserName' },
    { label: 'Comment Type', key: 'commentType' },
    { label: 'Comment URL', key: 'commentUrl' },
    { label: 'Post ID', key: 'postId' },
    { label: 'Post Type', key: 'postType' },
    { label: 'Post URL', key: 'postUrl' },
    { label: 'Keywords', key: 'keywords' },
    { label: 'Comment Tag', key: 'commentTag' },
    { label: 'Sentiments', key: 'sentiments' },
    { label: 'Intents', key: 'intents' },
  ];
  for (let i = 0; i < maxActions; ++i) {
    const seq = i + 1;
    const actionValue = 'Engagement ' + seq;
    const sourceValue = 'Engagement Source ' + seq;
    const userValue = 'Engagement User ' + seq;
    result.push({ label: actionValue, key: actionValue });
    result.push({ label: sourceValue, key: sourceValue });
    result.push({ label: userValue, key: userValue });
  }
  return result;
};

// eslint-disable-next-line max-lines-per-function
export const updateTagStates = (comments: Comments = {}, tags: any, posts: Post[]) => {
  const validSentiments = new Set(['Neutral', 'Positive', 'Negative', 'Mixed']);
  const activeTags = new Set<string>();
  const activePostTypes = new Set<string>();

  // Process comments and posts in a single pass
  for (const comment of Object.values(comments) as any) {
    const amplifyEngagedLabel = getAmplifyEngagedTypeLabel(comment, true);
    const combinedTags = { ...comment.tags, amplifyEngaged: amplifyEngagedLabel };

    // Add comment tags
    for (const tagGroup of Object.values(combinedTags) as any) {
      for (const tagId of Object.keys(tagGroup)) {
        activeTags.add(String(tagId));
      }
    }

    // Add amplify engaged type keys
    const amplifyEngagedTypeKeys = getAmplifyEngagementTypeNames(amplifyEngagedLabel);
    if (amplifyEngagedTypeKeys) {
      for (const key of amplifyEngagedTypeKeys) {
        activeTags.add(key);
      }
    }

    // Add sentiment tag if valid
    const userSentiment = comment?.userComment?.sentiment?.rating;
    if (userSentiment && validSentiments.has(capitalizeFirstLetter(userSentiment))) {
      activeTags.add(capitalizeFirstLetter(userSentiment));
    }
  }

  for (const post of posts) {
    if (post.postType === postType.PAID || post.postType === postType.ORGANIC) {
      activePostTypes.add(post.postType);
    }
  }

  // Update tags
  const updatedTags: any = {};
  for (const [group, tagGroup] of Object.entries(tags) as [string, any][]) {
    updatedTags[group] = {};
    for (const [tagId, tag] of Object.entries(tagGroup) as [string, any][]) {
      const updatedTag = { ...tag };

      if (defaultTagGroupNames.includes(group)) {
        updatedTag.disabled = !activeTags.has(tagId);
      } else if (group === TagGroupNames.POST_TYPES && (tagId === postType.PAID || tagId === postType.ORGANIC)) {
        updatedTag.disabled = !activePostTypes.has(tagId);
      } else if (group === TagGroupNames.SENTIMENT && validSentiments.has(tagId)) {
        updatedTag.disabled = !activeTags.has(tagId);
      }

      updatedTags[group][tagId] = updatedTag;
    }
  }

  return updatedTags;
};
