/* eslint-disable @typescript-eslint/naming-convention */
import { pathsDontNeedAccountAccountSelection } from './../common/page-ui-state/constants';
import { dashboardTabConstants } from '../pages/dashboard/constants';
import { deeplyLinkedRoutes } from '../common/page-ui-state/constants';

export const routeToPageTitle: { [key: string]: string } = {
  [pathsDontNeedAccountAccountSelection.chooseAccount]: 'Choose Account',
  [deeplyLinkedRoutes.adminTools]: 'Admin Tools',
  [deeplyLinkedRoutes.requestPageAccess]: 'Request Page Access',
  '/register': 'Register',
  '/login': 'Log In',
  '/forgot-password': 'Forgot Password',
  '/': 'Home',
  '/:accountId': 'Home',
  '/:accountId/:platformBotId/dashboard': 'Dashboard',
  [`/:accountId/:platformBotId/dashboard/${dashboardTabConstants.COMMENTS}`]: 'Dashboard | Comments',
  [`/:accountId/:platformBotId/dashboard/${dashboardTabConstants.MENTIONS}`]: 'Dashboard | Mentions',
  '/:accountId/:platformBotId/profile': 'Profile',
  '/:accountId/:platformBotId/accountSettings': 'Account Settings',
  '/:accountId/:platformBotId/manageUsers': 'Manage Users',
  '/:accountId/:platformBotId/socialMediaAccounts': 'Social Media Accounts',
  '/:accountId/:platformBotId/manageUsers/createUser': 'Create User',
  '/:accountId/deploy': 'Deploy',
  '/:accountId/chooseDeployPlatform': 'Choose Platform',
  '/:accountId/:platformBotId/tags': 'Tags',
  '/:accountId/:platformBotId/tags/standard': 'Tags | Standard',
  '/:accountId/:platformBotId/tags/custom': 'Tags | Custom',
  '/:accountId/:platformBotId/tags/editTag/:taggingCampaignId': 'Tags | Edit Tag',
  '/:accountId/:platformBotId/tags/createTag': 'Tags | Create Tag',
  '/:accountId/:platformBotId/keywordGroups': 'Keyword Groups',
  '/:accountId/:platformBotId/keywordGroups/standard': 'Keyword Groups | Standard',
  '/:accountId/:platformBotId/keywordGroups/custom': 'Keyword Groups | Custom',
  '/:accountId/:platformBotId/keywordGroups/editKeywordGroup/:keywordGroupId': 'Keyword Groups | Edit Keyword Group',
  '/:accountId/:platformBotId/keywordGroups/createKeywordGroup': 'Keyword Groups | Create Keyword Group',
  '/:accountId/:platformBotId/commentModeration': 'Moderation',
  '/:accountId/:platformBotId/commentModeration/standard': 'Moderation | Standard',
  '/:accountId/:platformBotId/commentModeration/custom': 'Moderation | Custom',
  '/:accountId/:platformBotId/brandLift': 'Brand Lift',
  '/:accountId/:platformBotId/brandLift/standard': 'Brand Lift | Standard',
  '/:accountId/:platformBotId/brandLift/custom': 'Brand Lift | Custom',
  '/:accountId/:platformBotId/smartReplies': 'Smart Replies',
  '/:accountId/:platformBotId/smartReplies/standard': 'Smart Replies | Standard',
  '/:accountId/:platformBotId/smartReplies/custom': 'Smart Replies | Custom',
  '/:accountId/:platformBotId/smartReplies/editCampaign/:campaignId': 'Automation | Smart Replies | Edit Campaign',
  '/:accountId/:platformBotId/commentModeration/editCampaign/:campaignId': 'Automation | Comment Moderation | Edit Campaign',
  '/:accountId/:platformBotId/brandLift/editCampaign/:campaignId': 'Automation | Brand Lift | Edit Campaign',
  '/:accountId/:platformBotId/smartReplies/createCampaign': 'Automation | Smart Replies | Create Campaign',
  '/:accountId/:platformBotId/commentModeration/createCampaign': 'Automation | Comment Moderation | Create Campaign',
  '/:accountId/:platformBotId/brandLift/createCampaign': 'Automation | Brand Lift | Create Campaign',
  [deeplyLinkedRoutes.templates]: 'Templates',
  [deeplyLinkedRoutes.templatesActive]: 'Templates | Active',
  [deeplyLinkedRoutes.templatesArchived]: 'Templates | Archived',
  [deeplyLinkedRoutes.editTemplate]: 'Templates | Edit Template',
  [deeplyLinkedRoutes.viewTemplate]: 'Templates | View Template',
  [deeplyLinkedRoutes.cloneTemplate]: 'Templates | Clone Template',
  [deeplyLinkedRoutes.createTemplate]: 'Templates | Create Template',
  '/:accountId/:platformBotId/reports': 'Reports',
  '/:accountId/:platformBotId/inbox': 'Inbox',
  '/:accountId/mobileonboarding': 'Mobile Onboarding',
  '/:accountId/:platformBotId/comments/moderation': 'Comment Moderation',
  '/:accountId/:platformBotId/comments/engagements': 'Comment Engagements',
  '/:accountId/:platformBotId/mentions/engagements': 'Mention Engagements',
  '/:accountId/:platformBotId/mentions/moderation': 'Mention Moderation',
  [deeplyLinkedRoutes.broadcasts]: 'Broadcasts',
  [deeplyLinkedRoutes.broadcastsDraft]: 'Draft Broadcasts',
  [deeplyLinkedRoutes.broadcastsScheduled]: 'Scheduled Broadcasts',
  [deeplyLinkedRoutes.broadcastsRepeat]: 'Repeat Broadcasts',
  [deeplyLinkedRoutes.broadcastsCompleted]: 'Archived Broadcasts',
  [deeplyLinkedRoutes.broadcastsTemplates]: 'Broadcasts Templates',
  [deeplyLinkedRoutes.broadcastsRecent]: 'Recent Broadcasts',
  [deeplyLinkedRoutes.editBroadcast]: 'Broadcasts | Edit Broadcast',
  [deeplyLinkedRoutes.cloneBroadcast]: 'Broadcasts | Clone Broadcast',
  [deeplyLinkedRoutes.viewBroadcast]: 'Broadcasts | View Broadcast',
  [deeplyLinkedRoutes.createBroadcast]: 'Broadcasts | Create Broadcast',
  [deeplyLinkedRoutes.accountCreation]: 'Admin Tools | Account Creation',
  [deeplyLinkedRoutes.smsDeployment]: 'Admin Tools | SMS Deployment',
  [deeplyLinkedRoutes.vanityNumber]: 'Admin Tools | Vanity Number',
  '/:accountId/:platformBotId/segments': 'Segments',
  '/:accountId/:platformBotId/manageLabels': 'Manage Labels',
  '/:accountId/:platformBotId/segments/editSegment/:segmentId': 'Segments | Edit Segments',
  '/:accountId/:platformBotId/segments/cloneSegment/:segmentId': 'Segments | Clone Segments',
  '/:accountId/:platformBotId/segments/createSegment': 'Segments | Create Segments',
  '/:accountId/:platformBotId/settings': 'Settings',
  '/:accountId/:platformBotId/utility': 'Utility',
  '/:accountId/:platformBotId/utility/qrCode': 'Utility | QRCode Generator',
  '/:accountId/:platformBotId/utility/shortUrl': 'Utility | ShortURL Generator',
  '/:accountId/:platformBotId/utility/mediaViewUrl': 'Utility | MediaViewURL Generator',
  '/:accountId/:platformBotId/settings/accountInfo': 'Account Info',
  '/:accountId/:platformBotId/settings/signUpMessages': 'Sign Up Messages',
  '/:accountId/:platformBotId/settings/botConfig': 'Bot Config',
  '/:accountId/:platformBotId/settings/accountConfig': 'Account Config',
  '/:accountId/:platformBotId/profileSettings': 'Profile settings',
  '/:accountId/:platformBotId/resources': 'Resources',
  '/:accountId/:platformBotId/pageHealth': 'Page Health',
  [deeplyLinkedRoutes.ingestListing]: 'Ingest List',
  [deeplyLinkedRoutes.ingestList]: 'Ingest',
  [deeplyLinkedRoutes.sentimentAnalysis]: 'Sentiment Analysis',
};
