/* eslint-disable @typescript-eslint/naming-convention */
export const twilioSmartEncoderMap: { [key: string]: string } = {
  '\u00AB': '"' /* U+00AB",*/,
  '\u00BB': '"' /* U+00BB",*/,
  '\u201C': '"' /* U+201C",*/,
  '\u201D': '"' /* U+201D",*/,
  '\u02BA': '"' /* U+02BA",*/,
  '\u02EE': '"' /* U+02EE",*/,
  '\u201F': '"' /* U+201F",*/,
  '\u275D': '"' /* U+275D",*/,
  '\u275E': '"' /* U+275E",*/,
  '\u301D': '"' /* U+301D",*/,
  '\u301E': '"' /* U+301E",*/,
  '\uFF02': '"' /* U+FF02",*/,
  '\u2018': "'" /* U+2018",*/,
  '\u2019': "'" /* U+2019",*/,
  '\u02BB': "'" /* U+02BB",*/,
  '\u02C8': "'" /* U+02C8",*/,
  '\u02BC': "'" /* U+02BC",*/,
  '\u02BD': "'" /* U+02BD",*/,
  '\u02B9': "'" /* U+02B9",*/,
  '\u201B': "'" /* U+201B",*/,
  '\uFF07': "'" /* U+FF07",*/,
  '\u00B4': "'" /* U+00B4",*/,
  '\u02CA': "'" /* U+02CA",*/,
  '\u0060': "'" /* U+0060",*/,
  '\u02CB': "'" /* U+02CB",*/,
  '\u275B': "'" /* U+275B",*/,
  '\u275C': "'" /* U+275C",*/,
  '\u0313': "'" /* U+0313",*/,
  '\u0314': "'" /* U+0314",*/,
  '\uFE10': "'" /* U+FE10",*/,
  '\uFE11': "'" /* U+FE11",*/,
  '\u00F7': '' /* /U+00F7"*/,
  '\u00BC': '1/4' /* U+00BC",*/,
  '\u00BD': '1/2' /* U+00BD",*/,
  '\u00BE': '3/4' /* U+00BE",*/,
  '\u29F8': '/' /* /U+29F8"*/,
  '\u0337': '/' /* /U+0337"*/,
  '\u0338': '/' /* /U+0338"*/,
  '\u2044': '/' /* /U+2044"*/,
  '\u2215': '/' /* /U+2215"*/,
  '\uFF0F': '/' /* /U+FF0F"*/,
  '\u29F9': '\\',
  '\u29F5': '\\' /* U+29F5",*/,
  '\u20E5': '\\' /* U+20E5",*/,
  '\uFE68': '\\' /* U+FE68",*/,
  '\uFF3C': '\\' /* U+FF3C",*/,
  '\u0332': '_' /* U+0332",*/,
  '\uFF3F': '_' /* U+FF3F",*/,
  '\u20D2': '|' /* U+20D2",*/,
  '\u20D3': '|' /* U+20D3",*/,
  '\u2223': '|' /* U+2223",*/,
  '\uFF5C': '|' /* U+FF5C",*/,
  '\u23B8': '|' /* U+23B8",*/,
  '\u23B9': '|' /* U+23B9",*/,
  '\u23D0': '|' /* U+23D0",*/,
  '\u239C': '|' /* U+239C",*/,
  '\u239F': '|' /* U+239F",*/,
  '\u23BC': '-' /* U+23BC",*/,
  '\u23BD': '-' /* U+23BD",*/,
  '\u2015': '-' /* U+2015",*/,
  '\uFE63': '-' /* U+FE63",*/,
  '\uFF0D': '-' /* U+FF0D",*/,
  '\u2010': '-' /* U+2010",*/,
  '\u2022': '-' /* U+2022",*/,
  '\u2043': '-' /* U+2043",*/,
  '\uFE6B': '@' /* U+FE6B",*/,
  '\uFF20': '@' /* U+FF20",*/,
  '\uFE69': '$' /* U+FE69",*/,
  '\uFF04': '$' /* U+FF04",*/,
  '\u01C3': '!' /* U+01C3",*/,
  '\uFE15': '!' /* U+FE15",*/,
  '\uFE57': '!' /* U+FE57",*/,
  '\uFF01': '!' /* U+FF01",*/,
  '\uFE5F': '#' /* U+FE5F",*/,
  '\uFF03': '#' /* U+FF03",*/,
  '\uFE6A': '%' /* U+FE6A",*/,
  '\uFF05': '%' /* U+FF05",*/,
  '\uFE60': '&' /* U+FE60",*/,
  '\uFF06': '&' /* U+FF06",*/,
  '\u201A': "'" /* U+201A",*/,
  '\u0326': ',' /* U+0326",*/,
  '\uFE50': ',' /* U+FE50",*/,
  '\u3001': ',' /* U+3001",*/,
  '\uFE51': ',' /* U+FE51",*/,
  '\uFF0C': ',' /* U+FF0C",*/,
  '\uFF64': ',' /* U+FF64",*/,
  '\u2768': '(' /* U+2768",*/,
  '\u276A': '(' /* U+276A",*/,
  '\uFE59': '(' /* U+FE59",*/,
  '\uFF08': '(' /* U+FF08",*/,
  '\u27EE': '(' /* U+27EE",*/,
  '\u2985': '(' /* U+2985",*/,
  '\u2769': ')' /* U+2769",*/,
  '\u276B': ')' /* U+276B",*/,
  '\uFE5A': ')' /* U+FE5A",*/,
  '\uFF09': ')' /* U+FF09",*/,
  '\u27EF': ')' /* U+27EF",*/,
  '\u2986': ')' /* U+2986",*/,
  '\u204E': '*' /* U+204E",*/,
  '\u2217': '*' /* U+2217",*/,
  '\u229B': '*' /* U+229B",*/,
  '\u2722': '*' /* U+2722",*/,
  '\u2723': '*' /* U+2723",*/,
  '\u2724': '*' /* U+2724",*/,
  '\u2725': '*' /* U+2725",*/,
  '\u2731': '*' /* U+2731",*/,
  '\u2732': '*' /* U+2732",*/,
  '\u2733': '*' /* U+2733",*/,
  '\u273A': '*' /* U+273A",*/,
  '\u273B': '*' /* U+273B",*/,
  '\u273C': '*' /* U+273C",*/,
  '\u273D': '*' /* U+273D",*/,
  '\u2743': '*' /* U+2743",*/,
  '\u2749': '*' /* U+2749",*/,
  '\u274A': '*' /* U+274A",*/,
  '\u274B': '*' /* U+274B",*/,
  '\u29C6': '*' /* U+29C6",*/,
  '\uFE61': '*' /* U+FE61",*/,
  '\uFF0A': '*' /* U+FF0A",*/,
  '\u02D6': '+' /* U+02D6",*/,
  '\uFE62': '+' /* U+FE62",*/,
  '\uFF0B': '+' /* U+FF0B",*/,
  '\u3002': '.' /* U+3002",*/,
  '\uFE52': '.' /* U+FE52",*/,
  '\uFF0E': '.' /* U+FF0E",*/,
  '\uFF61': '.' /* U+FF61",*/,
  '\uFF10': '0' /* U+FF10",*/,
  '\uFF11': '1' /* U+FF11",*/,
  '\uFF12': '2' /* U+FF12",*/,
  '\uFF13': '3' /* U+FF13",*/,
  '\uFF14': '4' /* U+FF14",*/,
  '\uFF15': '5' /* U+FF15",*/,
  '\uFF16': '6' /* U+FF16",*/,
  '\uFF17': '7' /* U+FF17",*/,
  '\uFF18': '8' /* U+FF18",*/,
  '\uFF19': '9' /* U+FF19",*/,
  '\u02D0': ':' /* U+02D0",*/,
  '\u02F8': ':' /* U+02F8",*/,
  '\u2982': ':' /* U+2982",*/,
  '\uA789': ':' /* U+A789",*/,
  '\uFE13': ':' /* U+FE13",*/,
  '\uFF1A': ':' /* U+FF1A",*/,
  '\u204F': ';' /* U+204F",*/,
  '\uFE14': ';' /* U+FE14",*/,
  '\uFE54': ';' /* U+FE54",*/,
  '\uFF1B': ';' /* U+FF1B",*/,
  '\uFE64': '<' /* U+FE64",*/,
  '\uFF1C': '<' /* U+FF1C",*/,
  '\u0347': '=' /* U+0347",*/,
  '\uA78A': '=' /* U+A78A",*/,
  '\uFE66': '=' /* U+FE66",*/,
  '\uFF1D': '=' /* U+FF1D",*/,
  '\uFE65': '>' /* U+FE65",*/,
  '\uFF1E': '>' /* U+FF1E",*/,
  '\uFE16': '?' /* U+FE16",*/,
  '\uFE56': '?' /* U+FE56",*/,
  '\uFF1F': '?' /* U+FF1F",*/,
  '\uFF21': 'A' /* U+FF21",*/,
  '\u1D00': 'A' /* U+1D00",*/,
  '\uFF22': 'B' /* U+FF22",*/,
  '\u0299': 'B' /* U+0299",*/,
  '\uFF23': 'C' /* U+FF23",*/,
  '\u1D04': 'C' /* U+1D04",*/,
  '\uFF24': 'D' /* U+FF24",*/,
  '\u1D05': 'D' /* U+1D05",*/,
  '\uFF25': 'E' /* U+FF25",*/,
  '\u1D07': 'E' /* U+1D07",*/,
  '\uFF26': 'F' /* U+FF26",*/,
  '\uA730': 'F' /* U+A730",*/,
  '\uFF27': 'G' /* U+FF27",*/,
  '\u0262': 'G' /* U+0262",*/,
  '\uFF28': 'H' /* U+FF28",*/,
  '\u029C': 'H' /* U+029C",*/,
  '\uFF29': 'I' /* U+FF29",*/,
  '\u026A': 'I' /* U+026A",*/,
  '\uFF2A': 'J' /* U+FF2A",*/,
  '\u1D0A': 'J' /* U+1D0A",*/,
  '\uFF2B': 'K' /* U+FF2B",*/,
  '\u1D0B': 'K' /* U+1D0B",*/,
  '\uFF2C': 'L' /* U+FF2C",*/,
  '\u029F': 'L' /* U+029F",*/,
  '\uFF2D': 'M' /* U+FF2D",*/,
  '\u1D0D': 'M' /* U+1D0D",*/,
  '\uFF2E': 'N' /* U+FF2E",*/,
  '\u0274': 'N' /* U+0274",*/,
  '\uFF2F': 'O' /* U+FF2F",*/,
  '\u1D0F': 'O' /* U+1D0F",*/,
  '\uFF30': 'P' /* U+FF30",*/,
  '\u1D18': 'P' /* U+1D18",*/,
  '\uFF31': 'Q' /* U+FF31",*/,
  '\uFF32': 'R' /* U+FF32",*/,
  '\u0280': 'R' /* U+0280",*/,
  '\uFF33': 'S' /* U+FF33",*/,
  '\uA731': 'S' /* U+A731",*/,
  '\uFF34': 'T' /* U+FF34",*/,
  '\u1D1B': 'T' /* U+1D1B",*/,
  '\uFF35': 'U' /* U+FF35",*/,
  '\u1D1C': 'U' /* U+1D1C",*/,
  '\uFF36': 'V' /* U+FF36",*/,
  '\u1D20': 'V' /* U+1D20",*/,
  '\uFF37': 'W' /* U+FF37",*/,
  '\u1D21': 'W' /* U+1D21",*/,
  '\uFF38': 'X' /* U+FF38",*/,
  '\uFF39': 'Y' /* U+FF39",*/,
  '\u028F': 'Y' /* U+028F",*/,
  '\uFF3A': 'Z' /* U+FF3A",*/,
  '\u1D22': 'Z' /* U+1D22",*/,
  '\u02C6': '^' /* U+02C6",*/,
  '\u0302': '^' /* U+0302",*/,
  '\uFF3E': '^' /* U+FF3E",*/,
  '\u1DCD': '^' /* U+1DCD",*/,
  '\u2774': '{' /* U+2774",*/,
  '\uFE5B': '{' /* U+FE5B",*/,
  '\uFF5B': '{' /* U+FF5B",*/,
  '\u2775': '}' /* U+2775",*/,
  '\uFE5C': '}' /* U+FE5C",*/,
  '\uFF5D': '}' /* U+FF5D",*/,
  '\uFF3B': '[' /* U+FF3B",*/,
  '\uFF3D': ']' /* U+FF3D",*/,
  '\u02DC': '~' /* U+02DC",*/,
  '\u02F7': '~' /* U+02F7",*/,
  '\u0303': '~' /* U+0303",*/,
  '\u0330': '~' /* U+0330",*/,
  '\u0334': '~' /* U+0334",*/,
  '\u223C': '~' /* U+223C",*/,
  '\uFF5E': '~' /* U+FF5E",*/,
  '\u00A0': "'" /* U+00A0",*/,
  '\u2000': "'" /* U+2000",*/,
  '\u2001': '' /* U+2001",*/,
  '\u2002': '' /* U+2002",*/,
  '\u2003': '' /* U+2003",*/,
  '\u2004': '' /* U+2004",*/,
  '\u2005': '' /* U+2005",*/,
  '\u2006': '' /* U+2006",*/,
  '\u2007': '' /* U+2007",*/,
  '\u2008': '' /* U+2008",*/,
  '\u2009': '' /* U+2009",*/,
  '\u200A': '' /* U+200A",*/,
  '\u200B': '' /* U+200B",*/,
  '\u202F': '' /* U+202F",*/,
  '\u205F': '' /* U+205F",*/,
  '\u3000': '' /* U+3000",*/,
  '\uFEFF': '' /* U+FEFF",*/,
  '\u008D': '' /* U+008D",*/,
  '\u009F': '' /* U+009F",*/,
  '\u0080': '' /* U+0080",*/,
  '\u0090': '' /* U+0090",*/,
  '\u009B': '' /* U+009B",*/,
  '\u0010': '' /* U+0010",*/,
  '\u0009': '' /* U+0009",*/,
  '\u0000': '' /* U+0000",*/,
  '\u0003': '' /* U+0003",*/,
  '\u0004': '' /* U+0004",*/,
  '\u0017': '' /* U+0017",*/,
  '\u0019': '' /* U+0019",*/,
  '\u0011': '' /* U+0011",*/,
  '\u0012': '' /* U+0012",*/,
  '\u0013': '' /* U+0013",*/,
  '\u0014': '' /* U+0014",*/,
  '\u2017': '_' /* U+2017",*/,
  '\u2014': '-' /* U+2014",*/,
  '\u2013': '-' /* U+2013",*/,
  '\u2039': '>' /* U+2039",*/,
  '\u203A': '<' /* U+203A",*/,
  '\u203C': '!!' /* U+203C",*/,
  '\u201E': '"' /* U+201E",*/,
  '\u2026': '...' /* U+2026",*/,
  '\u2028': '' /* U+2028",*/,
  '\u2029': '' /* U+2029",*/,
  '\u2060': '' /* U+2060"*/,
};
