import { Location } from 'history';
import _, { sortBy } from 'lodash';
import moment from 'moment';
import { VariableType } from '../common/entities/entities.types';
import { decodeString } from '../common/utils/persist-time-filter';
import { FBConfig } from '../config';
import { IfStatementType } from '../pages/automation/types';
import { convertSquareBracketToDotation, getSegmentIdTagIdForAPI } from '../pages/broadcasts/utils';
import { GCChildCondition, GCConditions } from '../types/api-calls';
import { defaultTwigTemplate, variableRegex } from './regex';
import { MergeTagMode } from '../pages/broadcasts/components/manage-broadcast/components/merge-tags-modal';
import { extractDateFormattedValues } from './twig-utils';

export const isLocalhost = () => {
  return (
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
};

export const getFiltersFromUrl = (currentURL: string = '') => {
  const url = new URL(currentURL || window.location.href);
  const params = url.searchParams.get('filters') || '';
  const urlParams: any = decodeString(params);
  return urlParams;
};

export const sortArrayOfStrings = (arrayString: string[]) => {
  return arrayString.slice().sort(function (a: string, b: string) {
    return a.localeCompare(b);
  });
};

export const updateTabStateOnBack = (updatedLocation: Location, setCurrentTab: (tabName: string) => void, routeNames: { [key: string]: string }) => {
  const updatedTab = getTabBySplitPath(updatedLocation.pathname);
  const foundTabKey = findTabKey(updatedTab, routeNames);
  foundTabKey && setCurrentTab(foundTabKey);
};

export const getTabBySplitPath = (pathname: string) => {
  const splitPath = pathname.split('/');
  return splitPath[splitPath.length - 1];
};
export const findTabKey = (updatedTab: string, routeNames: { [key: string]: string }) => Object.keys(routeNames).find((tabName: string) => updatedTab === routeNames[tabName]);

export const getKeyByValue = (object: { [key in string | number]: string | number }, value: unknown): number | string | undefined => {
  return Object.keys(object).find((key: string | number) => object[key] === value);
};

export const deleteCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
export const deleteFBCookie = () => {
  deleteCookie('fblo_' + FBConfig.id);
};

export const removeAllWhiteSpaces = (message?: string) => {
  return message && message.replace(/\s/g, ' ');
};
export const isCharacterALetter = (char: string) => {
  return /[a-zA-Z]/.test(char);
};

export const isCharacterANumber = (char: string) => {
  const re = /^\d$/;
  return function () {
    return re.test(char);
  };
};

export const sortEntitiesByDateColumnDesc = (entities: any[], columnName: string) => {
  if (!entities || !Array.isArray(entities)) {
    return entities;
  }
  const sortedEntities = sortBy(entities, (entity: any) => {
    return moment(entity[columnName], 'YYYY/MM/DD [at] hh:mm A');
  }).reverse();
  return sortedEntities;
};

export const formattedPhoneInput = (value: string) => {
  if (!value) return value;

  const nums = value.replace(/[^\d]/g, '');

  //return '';
  if (nums.length === 0) return '';

  // returns: "(xxx" "(x" "(xx"
  if (nums.length < 4) return `(${nums}`;

  // returns: "(xxx) xxx" "(xxx) x" "(xxx) xx"
  if (nums.length < 7) return `(${nums.slice(0, 3)}) ${nums.slice(3)}`;

  // returns: "(xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx",
  return `(${nums.slice(0, 3)}) ${nums.slice(3, 6)}-${nums.slice(6, 10)}`;
};

export const removeIdsFromConditions = (conditions: GCConditions) => {
  const newConditions = _.cloneDeep(conditions);
  newConditions?.childConditions?.forEach((childCondition: GCChildCondition) => {
    delete childCondition['id'];
    childCondition?.ifStatements?.forEach((ifStatement: IfStatementType) => {
      delete ifStatement['id'];
    });
    if (childCondition?.segmentId !== undefined) {
      childCondition?.childConditions?.forEach((childC: any) => {
        const { tagId, tagGroupName } = getSegmentIdTagIdForAPI(childC.segmentId);
        if (tagGroupName && tagId) {
          childC.tagId = tagId;
          childC.tagGroupName = tagGroupName;
          delete childC.segmentId;
        }
        delete childC['id'];
      });
    }
  });

  return newConditions;
};

export const getRedirectPath = (stateString?: string) => {
  if (!stateString) return '';
  const stateValDecoded = decodeURIComponent(stateString);
  return JSON.parse(`${stateValDecoded || {}}`)?.path;
};

export const removeVarBraces = (variable?: any) => {
  return variable?.macro.replace('{{', '').replace('}}', '').trim();
};

export const convertToTwigSyntax = (variable?: any, expression: string = '') => {
  if (expression) {
    return variable ? `{{${(removeVarBraces(variable))}|default('${expression}')}}` : '';
  }
  return variable?.macro;
};

export const convertFromTwigSyntaxToDisplayText = (twigSyntax?: any, availableVariables?: VariableType[]) => {
  const [firstPart, secondPart] = twigSyntax.split('|');
  const macro = `{{${firstPart.replace('{{', '').trim()}}}`;
  const variable = availableVariables?.find((v: VariableType) => v.macro === macro);
  const defaultValue = secondPart
    ? `${secondPart
      .replace('default', '')
      .replaceAll(/[}()'"]/gi, '')
      .trim()}`
    : '';

  const varName = `{${variable?.name || macro?.replace('{{', '').replace('}}', '')}${defaultValue ? ` | ${defaultValue}` : ''}}`;

  return varName;
};

export const getDetailsFromTwigSyntax = (twigSyntax?: any, availableVariables?: VariableType[], mode?: MergeTagMode) => {
  let variable;
  let defaultValue = '';
  let selectedDateFormat = '';
  let dateFormatterDefault = '';

  if (mode === 'default') {
    const defaultTemplateMatch = twigSyntax?.match(defaultTwigTemplate);
    if (defaultTemplateMatch) {
      const [, macrosWithoutBraces = '', defaultVal = ''] = twigSyntax?.match(defaultTwigTemplate) || [];
      const macro = `{{${macrosWithoutBraces?.trim()}}}`;
      variable = availableVariables?.find((v: VariableType) => v.macro === macro);
      defaultValue = defaultVal?.trim();
    } else if (twigSyntax?.match(variableRegex)) {
      variable = availableVariables?.find((v: VariableType) => v.macro === twigSyntax);
    }
  } else if (mode === 'dateFormatter') {
    const { variable: variableWithoutBraces, dateFormat, elsePart } = extractDateFormattedValues(twigSyntax);
    const withDotNotation = convertSquareBracketToDotation(variableWithoutBraces);
    const macro = `{{${withDotNotation}}}`;
    variable = availableVariables?.find((v: VariableType) => v.macro === macro);
    dateFormatterDefault = elsePart;
    selectedDateFormat = dateFormat;
  }

  return { variableSelected: variable, selectedDateFormat, dateFormatter: dateFormatterDefault, default: defaultValue, mode, advanced: mode === 'advanced' ? twigSyntax?.trim() : '' };
};

export const getAccountEngagementSettingsForDonation = (enableDonation: boolean = false) => {
  return {
    pageStatsMetadata: {
      donation: {
        disabledByDefault: !enableDonation,
      },
      donationAverage: {
        disabledByDefault: !enableDonation,
      },
      donationCount: {
        disabledByDefault: !enableDonation,
      },
    },
  };
};

export const getEngagementSettingsForSegmentCost = (smsMmsCostConfig?: any) => {
  const { costPerSMSSegmentsInCents, costPerMMSInCents } = smsMmsCostConfig;
  return {
    pageStatsMetadata: {
      estimatedSMSSegmentCost: {
        disabledByDefault: !costPerSMSSegmentsInCents,
        valuesToSpread: {
          costPerSMSSegmentsInCents,
        },
      },
      estimatedMMSCost: {
        disabledByDefault: !costPerMMSInCents,
        valuesToSpread: {
          costPerMMSInCents,
        },
      },
    },
  };
};

export const removeRegexMatchAndGiveList = (str: string, matcher: RegExp | string, counterString: string = 'Counter') => {
  const matcherList: { [key: string]: string } = {};
  let counter = 0;
  const text = str.replaceAll(matcher, (match: string) => {
    matcherList[`[[${counterString}${counter}]]`] = match;
    return `[[${counterString}${counter++}]]`;
  });
  return { text, list: matcherList };
};

export const updateMatcherStringsWithList = (str: string, matcherList: { [key: string]: string }) => {
  let text = str;
  Object.entries(matcherList).forEach(([key, value]: [string, string]) => {
    text = text.replace(key, value);
  });
  return text;
};
