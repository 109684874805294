import { bulkActionTypesAPI, inboxConstants, messageStatusConstants } from './constants';

export const messageTypeAiToUi = {
  active: inboxConstants.ACTIVE,
  engaged: inboxConstants.ENGAGED,
  blocked: inboxConstants.BLOCKED,
  ignore: inboxConstants.DND,
  reviewed: inboxConstants.NOT_CRITICAL,
  assigned: inboxConstants.ASSIGNED,
};

export const inboxMessagesPerPage = 50;
export const pastMessagesInDays = 90;
export const conversationPerPage = 50;
export const messageCountLimitForBulkOPeration = 100;

export const tabsToMessageStatus = {
  [inboxConstants.ASSIGNED]: [inboxConstants.ACTIVE, inboxConstants.ENGAGED, inboxConstants.NOT_CRITICAL, inboxConstants.DND, inboxConstants.BLOCKED],
  [inboxConstants.ACTIONABLE]: [inboxConstants.ACTIVE, inboxConstants.ENGAGED, inboxConstants.NOT_CRITICAL], //first item of the list will be sent as status when moved from one tab to other
  [inboxConstants.NON_ACTIONABLE]: [inboxConstants.DND, inboxConstants.BLOCKED],
  [inboxConstants.SEARCH]: [inboxConstants.ACTIVE, inboxConstants.ENGAGED, inboxConstants.NOT_CRITICAL, inboxConstants.DND, inboxConstants.BLOCKED],
};

export const colorsForCustomLabel = [
  '#FFE3B8',
  '#FFB4B4',
  '#C8ECFF',
  '#C6D1EE',
  '#DFEAC3',
  '#F7D05B',
  '#D54C37',
  '#DE7832',
  '#5A9D54',
  '#4A918E',
  '#4182CA',
  '#9364C1',
  '#BE5BB0',
  '#B1B7BA',
  '#F5BFB5',
  '#F5C28D',
  '#9DD096',
  '#8FCFCC',
  '#95C7F3',
  '#CEB0F1',
  '#ECADE4',
];
export const messageStatusFilters = [messageStatusConstants.NEW, messageStatusConstants.ENGAGED, messageStatusConstants.READ];
export const messageStatusAll = [messageStatusConstants.NEW, messageStatusConstants.ENGAGED, messageStatusConstants.READ] as const;

export const assignedStatusAll = [inboxConstants.ASSIGNED, inboxConstants.UNASSIGNED];

export const messageStatusToIcon = {
  [messageStatusConstants.NEW]: '/images/icons/email-gray.svg',
  [messageStatusConstants.ENGAGED]: '/images/icons/check-mark.svg',
  [messageStatusConstants.READ]: '/images/icons/mail-open.svg',
};

export const actionTypesToPayload = {
  [bulkActionTypesAPI.REPLY_MESSAGE]: 'replyMessage',
  [bulkActionTypesAPI.UPDATE_MESSAGE_STATUS]: 'status',
  [bulkActionTypesAPI.UPDATE_INBOX_STATUS]: 'status',
  [bulkActionTypesAPI.UPDATE_CUSTOM_LABELS]: 'customLabels',
};

export const actionToLabel = {
  [bulkActionTypesAPI.ASSIGN]: 'Assign',
  [bulkActionTypesAPI.REPLY_MESSAGE]: 'Reply Message',
  [bulkActionTypesAPI.UPDATE_MESSAGE_STATUS]: 'Update Message Status',
  [bulkActionTypesAPI.UPDATE_INBOX_STATUS]: 'Move to new Tab',
  [bulkActionTypesAPI.UPDATE_CUSTOM_LABELS]: 'Apply Custom Label',
};

export const webRendererConfig = {
  leftGalleryArrowImageUrl: '/images/icons/left.svg',
  rightGalleryArrowImageUrl: '/images/icons/right.svg',
  galleryTransitionWidth: 390,
};

export const OverriddenErroMessages: any = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  10: "This user haven't interacted with page in last 24 hours hence we can't send a message to user.",
};

export const inboxPollInterval = {
  messagePollingInterval: 10000,
  conversationInterval: 10000,
  clearIntervalForMessage: 300000,
  clearIntervalForConversation: 300000,
};

export const scrollHeightToStopAutoScrollToBottom = 300; // scrolled height, more than this, automatic scroll to bottom will be disabled
