/*eslint-disable max-lines*/
/*eslint-disable max-lines-per-function*/
import GraphemeSplitter from 'grapheme-splitter';
import { availableFontList, fontize } from './unicodeTransforms/fonts';
import htmlToUnicodeTransformers from './unicodeTransforms/formats';

const Saxophone = require('../saxophone/lib');
const fontFamilyRegex = /font-family:\s*([^;]+)\s*;/;
/**
 * Turns an html string into an unicode string.
 *
 * @param {string} html the source html
 * @returns {Promise<String>} an unicode string.
 *
 * @example
 *     await html2unicode("Hello, <b>world</b> !");
 *     // --> "Hello, 𝘄𝗼𝗿𝗹𝗱!"
 **/
function html2unicode(htmlString: string): Promise<string> {
  const chunks: any[] = [];
  const parser = new Saxophone();
  const html = htmlString.replaceAll('<p><br></p>', '\n').replaceAll('</p>', '</p>\n');
  const tags: any = {
    i: 0,
    em: 0,
    b: 0,
    strong: 0,
    // pre: 0,
    // code: 0,
    // tt: 0,
    // samp: 0,
    // kbd: 0,
    // var: 0,
    // sub: 0,
    // sup: 0,
    s: 0,
    u: 0,
    span: 0,
  };
  const fonts: string[] = [];
  parser.on('tagopen', (tagProps: any) => {
    const { name, isSelfClosing, attrs = '' } = tagProps;
    if (!isSelfClosing && tags.hasOwnProperty(name)) {
      tags[name]++;
      if (name === 'span') {
        const fontFamily = attrs.match(fontFamilyRegex)?.[1];
        if (fontFamily) fonts.push(fontFamily);
      }
    }
  });
  parser.on('tagclose', (tagProps: any) => {
    if (tags.hasOwnProperty(tagProps.name)) {
      tags[tagProps.name]--;
      if (tagProps.name === 'span' && fonts.length) {
        fonts.pop();
      }
    }
  });
  const state: any = {
    bold: false,
    italics: false,
    // mono: false,
    // variable: false,
    // sub: false,
    // sup: false,
    stricken: false,
    underlined: false,
    font: null,
  };
  parser.on('text', ({ contents }: any) => {
    contents = contents
      .replaceAll(/&nbsp;/g, ' ')
      .replaceAll(/&amp;/g, '&')
      .replaceAll(/&lt;/g, '<')
      .replaceAll(/&gt;/g, '>');
    state.bold = tags.b > 0 || tags.strong > 0;
    state.italics = tags.i > 0 || tags.em > 0;
    // state.mono = tags.code > 0 || tags.tt > 0 || tags.pre > 0 || tags.samp > 0 || tags.kbd > 0;
    // state.variable = tags['var'] > 0;
    // state.sub = tags.sub > 0;
    // state.sup = tags.sup > 0;
    state.stricken = tags.s > 0;
    state.underlined = tags.u > 0;
    state.font = tags.span && fonts[fonts.length - 1] ? fonts[fonts.length - 1] : null;
    chunks.push(transformCharWithStateToUnicode(contents, state));
  });
  return new Promise<string>((resolve: any) => {
    try {
      parser.on('finish', () => {
        /* remove characters which are replaced as html entities from https://www.w3schools.com/HTML/html_entities.asp*/
        let str = chunks.join('') || '';
        if (str.lastIndexOf('\n') === str.length - 1) {
          str = str.substring(0, str.length - 1);
        }
        resolve(str);
      });
      parser.on('error', (err: any) => {
        /*eslint-disable-next-line no-console*/
        console.error(err);
        resolve(html);
      });
      parser.parse(html);
    } catch (ex) {
      /*eslint-disable-next-line no-console*/
      console.error(ex);
      resolve(html);
    }
  });
}

/**
 * Transforms a text according to the given options
 *
 * @example
 *     transform("world", {bold: true});
 *      // --> "𝘄𝗼𝗿𝗹𝗱"
 *
 * @example
 *     transform("world", {bold: true, italics: true});
 *      // --> "𝙬𝙤𝙧𝙡𝙙"
 *
 * @example
 *     transform("n", {sup: true});
 *      // --> "ⁿ"
 *
 * @example
 *     transform("text", {mono: true});
 *      // --> "𝚝𝚎𝚡𝚝"
 **/
function transformCharWithStateToUnicode(text: string, { bold, italics, /* mono, variable, sub, sup,*/ stricken, underlined, font }: any) {
  // text = text.normalize('NFKD');//normalization removes unicode characters present inside html tag
  if (underlined) text = htmlToUnicodeTransformers.underline(text);
  else if (stricken) text = htmlToUnicodeTransformers.strikeThrough(text);
  else if (bold && italics) text = htmlToUnicodeTransformers.boldenAndItalicize(text);
  else if (bold) text = htmlToUnicodeTransformers.bolden(text);
  else if (italics) text = htmlToUnicodeTransformers.italicize(text);
  // else if (sub) text = htmlToUnicodeTransformers.subscript(text);
  // else if (sup) text = htmlToUnicodeTransformers.superscript(text);
  // else if (mono) text = htmlToUnicodeTransformers.monospace(text);
  // else if (variable) text = htmlToUnicodeTransformers.scriptize(text);
  else if (font) text = fontize(text, font);
  return text;
}

function unicode2html(text: string, regexToReplace?: RegExp) {
  const replaceSet: { [key: string]: string } = {};
  if (regexToReplace) {
    let replacedCharCount = 0;
    text = text.replaceAll(regexToReplace, (match: string) => {
      const replaceChar = `[[AmpLite_Reg_${++replacedCharCount}]]`;
      replaceSet[replaceChar] = match;
      return replaceChar;
    });
  }
  const splitter = new GraphemeSplitter();
  const textArray = splitter.splitGraphemes(text);
  const charAndStateArray = textArray.map((i: string) => transformUnicodeToCharWithState(i));
  let htmlString = '<p>';
  let previousTags: string[] = [];
  let previousFont: string = '';
  const getStartTag = (tag: string, font: string = '') => `<${tag === 'span' ? `${tag} style="font-family:${font};"` : tag}>`;
  textArray.forEach((_i: string, index: number) => {
    const { character, tags = [], font = '' } = charAndStateArray[index];
    if (!tags.length) {
      htmlString += `${[...previousTags]
        .reverse()
        .map((t: string) => `</${t}>`)
        .join('')}${character.charCodeAt(0) === 10 ? (htmlString.slice(-3) === '<p>' ? '<br></p><p>' : '</p><p>') : character}`;
      previousTags = [];
      previousFont = '';
    } else if (tags.length === 2) {
      if (previousTags.length === 2) {
        htmlString += character;
      } else if (tags.includes(previousTags[0])) {
        const missedTag = tags[tags[0] === previousTags[0] ? 1 : 0];
        htmlString += `${getStartTag(missedTag, font)}${character}`;
        previousTags.push(missedTag);
      } else {
        htmlString += `${[...previousTags]
          .reverse()
          .map((t: string) => `</${t}>`)
          .join('')}${tags.map((t: string) => `${getStartTag(t, font)}`).join('')}${character}`;
        previousTags = tags;
      }
      previousFont = '';
    } else if (tags.length) {
      if (previousTags.length === tags.length) {
        if (previousTags[0] === tags[0] && font === previousFont) {
          htmlString += character;
        } else {
          htmlString += `</${previousTags[0]}>${getStartTag(tags[0], font)}${character}`;
          previousTags = tags;
        }
      } else if (!previousTags.length) {
        htmlString += `${getStartTag(tags[0], font)}${character}`;
        previousTags = tags;
      } else if (previousTags[0] === tags[0]) {
        htmlString += `</${previousTags[1]}>${character}`;
        previousTags.pop();
      } else {
        htmlString += `${[...previousTags]
          .reverse()
          .map((t: string) => `</${t}>`)
          .join('')}${getStartTag(tags[0], font)}${character}`;
        previousTags = tags;
      }
      previousFont = font;
    }
    if (index === textArray.length - 1) {
      htmlString += `${[...previousTags]
        .reverse()
        .map((t: string) => `</${t}>`)
        .join('')}</p>`;
    }
  });
  Object.entries(replaceSet).forEach(([replacedValue, originalValue]: [string, string]) => {
    htmlString = htmlString.replace(replacedValue, originalValue);
  });
  return htmlString;
}

const transformUnicodeToCharWithState = (char: string) => {
  if (char === '\n' || char === ' ') return { character: char };
  const splitter = new GraphemeSplitter();
  for (const font in availableFontList) {
    const unicodeCharactersList = splitter.splitGraphemes((availableFontList as any)[font].unicodeCharactersList);
    const index = unicodeCharactersList.indexOf(char);
    if (index > -1) {
      return { character: String.fromCharCode(index <= 25 ? 65 + index : index <= 51 ? 97 + (index - 26) : 48 + (index - 52)), tags: ['span'], font };
    }
  }
  const charCodes = char.split('').map((i: string) => i.charCodeAt(0));
  if (charCodes.length === 2) {
    if (charCodes[1] === 863) {
      //underline
      return { character: String.fromCharCode(charCodes[0]), tags: ['u'] };
    } else if (charCodes[1] === 821) {
      //strikethrough
      return { character: String.fromCharCode(charCodes[0]), tags: ['s'] };
    } else if (charCodes[0] === 55349) {
      if (charCodes[1] >= 57324 && charCodes[1] <= 57333) {
        //bold numbers
        return { character: String.fromCharCode(charCodes[1] - 57324 + 48), tags: ['strong'] };
      } else if (charCodes[1] >= 56814 && charCodes[1] <= 56839) {
        //bold smallcase letters
        return { character: String.fromCharCode(charCodes[1] - 56814 + 97), tags: ['strong'] };
      } else if (charCodes[1] >= 56788 && charCodes[1] <= 56813) {
        //bold uppercase letters
        return { character: String.fromCharCode(charCodes[1] - 56788 + 65), tags: ['strong'] };
      } else if (charCodes[1] >= 56840 && charCodes[1] <= 56865) {
        //italics uppercase letters
        return { character: String.fromCharCode(charCodes[1] - 56840 + 65), tags: ['i'] };
      } else if (charCodes[1] >= 56866 && charCodes[1] <= 56891) {
        //italics uppercase letters
        return { character: String.fromCharCode(charCodes[1] - 56866 + 97), tags: ['i'] };
      } else if (charCodes[1] >= 56892 && charCodes[1] <= 56917) {
        //bold italics uppercase letters
        return { character: String.fromCharCode(charCodes[1] - 56892 + 65), tags: ['strong', 'i'] };
      } else if (charCodes[1] >= 56918 && charCodes[1] <= 56943) {
        //bold italics lowercase letters
        return { character: String.fromCharCode(charCodes[1] - 56918 + 97), tags: ['strong', 'i'] };
        // } else if (charCodes[1] >= 56944 && charCodes[1] <= 56969) {
        //   //mono uppercase letters
        //   return { character: String.fromCharCode(charCodes[1] - 56944 + 65), tags: ['pre'] };
        // } else if (charCodes[1] >= 56970 && charCodes[1] <= 56995) {
        //   //mono lowercase letters
        //   return { character: String.fromCharCode(charCodes[1] - 56970 + 97), tags: ['pre'] };
        // } else if (charCodes[1] >= 56528 && charCodes[1] <= 56553) {
        //   //cursive uppercase letters
        //   return { character: String.fromCharCode(charCodes[1] - 56528 + 65), tags: ['span'] };
        // } else if (charCodes[1] >= 56554 && charCodes[1] <= 56579) {
        //   //cursive lowercase letters
        //   return { character: String.fromCharCode(charCodes[1] - 56554 + 97), tags: ['span'] };
      }
      return { character: char };
    }
  }
  return { character: char };
};

export { html2unicode, unicode2html };
