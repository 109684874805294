import { Dispatch, SetStateAction } from 'react';
import { clearTempAuth, setAuthToken, setTempAuthDetails, storeUserDetails, storeUserPermissions } from '.';
import { get, post } from '../../../common/api-calls/api-client';
import { isAccountsFetched, setPageUIState } from '../../../common/page-ui-state/actions';
import { showErrorModal } from '../../../common/utils';
import { AsyncThunkDispatch, History, RootState, Thunk } from '../../../types';
import { NotificationTypes, showMessage } from '../../common/toast-notification';
import { getUsers } from '../../user-profile-pages/actions/async-actions';
import { maxAttemptsAllowedForOtp } from '../login.constants';
import { AuthToken, LoginFormFields } from '../login.types';
import transform from '../transformer';
import { authenticateUser } from '../utils';

type LoginSuccessParams = {
  token: AuthToken;
  history: History;
  email: string;
  routeToAccess?: string;
};
/* eslint-disable @typescript-eslint/naming-convention */
export const attemptLogin = (
  loginFields: LoginFormFields,
  history: History,
  onFail: (error: string) => void,
  onSuccess: (email: string) => void,
  routeToAccess?: string,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    let response = null;
    try {
      response = await post('login', {
        username: loginFields.email,
        password: loginFields.password,
      });
    } catch (error: any) {
      const errorMessage =
        error.response?.status === 400 && error.response?.data?.message
          ? 'User locked out. Retry after 5 minutes.'
          : error.response?.status === 403
          ? error.response?.data?.message
          : '';
      onFail(errorMessage);
    }
    if (response && response.data) {
      const token = transform.attemptLoginApiToUi(response.data);
      const mfaSettings = transform.transformMfaSettings(response.data);
      if (mfaSettings && (mfaSettings.enabled || (mfaSettings.required && (!mfaSettings.requiredFrom || mfaSettings.requiredFrom < Date.now())))) {
        dispatch(setTempAuthDetails(token.authToken, loginFields.email, response.data.qrCodeUri));
        const state = history.location.state as any;
        history.push(mfaSettings.enabled ? `/verifyOtp` : '/enableMfa', state?.from ? { from: state?.from as string } : undefined);
      } else if (response.data.success) {
        dispatch(afterLoginSuccess({ token, history, email: loginFields.email, routeToAccess }, onSuccess, true));
      }
    }
  };
};

export const afterLoginSuccess = ({ token, history, email, routeToAccess }: LoginSuccessParams, onSuccess: (email: string) => void, isDirectLogin?: boolean) => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    dispatch(setAuthToken(token));
    dispatch(isAccountsFetched(true));
    dispatch(
      getUserDetails(
        history,
        (email: string) => {
          onSuccess(email);
          if (!isDirectLogin) {
            dispatch(clearTempAuth());
          }
        },
        email,
        routeToAccess,
      ),
    );
    dispatch(getUserPermissions());
    if (isDirectLogin) {
      dispatch(clearTempAuth());
    }
  };
};

export const activateMfa = (otpCode: string, history: History, onFail: () => void, onSuccess: (email: string) => void, isUserLoggedIn: boolean, routeToAccess?: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const { authToken, email = '' } = getState().auth.tempAuthDetails || {};
    try {
      response = await post('login/activateMfa', { otpCode }, authToken as string);
      if (response && response.data && response.data.success) {
        dispatch(setPageUIState({ key: 'isMFAActivated', value: true }));
        showMessage(`MFA enabled successfully, redirecting...`, '', NotificationTypes.NORMAL);
        const token = transform.attemptLoginApiToUi(response.data);
        if (!isUserLoggedIn) {
          dispatch(afterLoginSuccess({ token, history, email, routeToAccess }, onSuccess));
        } else {
          dispatch(setAuthToken(token));
          dispatch(clearTempAuth());
          dispatch(getUserDetailsForIframe());
          setTimeout(() => {
            authenticateUser(history, routeToAccess);
          }, 2000);
        }
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showErrorModal(error, dispatch);
      } else {
        onFail();
      }
    }
  };
};

export const deactivateMfa = (dashboardUserId: string, done?: (isSuccess: boolean) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const { authToken } = getState().auth || {};
    try {
      response = await post(`login/deactivateMfa/${dashboardUserId}`, {}, authToken as string);
      if (response && response.data && response.status === 200) {
        dispatch(setPageUIState({ key: 'isMFAActivated', value: false }));
        dispatch(getUsers());
        done && done(true);
      }
    } catch (error: any) {
      done && done(false);
      showErrorModal(error, dispatch);
    }
  };
};

export const setupMfa = (onFail: (errorMessage: string) => void, onSuccess?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const email = state.auth.user?.email || '';
    try {
      response = await post('login/setupMfa', undefined, authToken as string);
      if (response && response.data && response.data.qrCodeUri) {
        dispatch(setTempAuthDetails(authToken as string, email, response.data.qrCodeUri));
        onSuccess?.();
      }
    } catch (error: any) {
      onFail(error?.response?.message);
    }
  };
};

export const verifyMfaOtp = (otpCode: string, done: (email: string) => void, history: History, verifyOtpApiCounter: number = 1, routeToAccess?: string) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const { authToken, email = '' } = getState().auth?.tempAuthDetails || {};
    try {
      response = await post(`/login/verifyOtp`, { otpCode }, authToken as string);
      if (response && response.data && response.data.success) {
        const token = transform.attemptLoginApiToUi(response.data);
        dispatch(afterLoginSuccess({ token, history, email, routeToAccess }, done));
      } else {
        done('');
      }
    } catch (error: any) {
      const maxOtpAttempts = error.response?.status === 400 && verifyOtpApiCounter >= maxAttemptsAllowedForOtp;
      if (error.response?.status === 401 || maxOtpAttempts) {
        const updatedError = { ...error };
        maxOtpAttempts &&
          updatedError?.response?.data &&
          (updatedError.response.data.redirectToLoginMessage = 'User locked out! Redirecting to login page. Please try again after 5 minutes.');
        showErrorModal(updatedError, dispatch);
      } else {
        done('');
      }
    }
  };
};

export const getUserDetails = (history: History, onSuccess: (email: string) => void, userEmail?: string, routeToAccess?: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const authToken = getState().auth.authToken;
    try {
      response = await get('users/currentUserDetails', authToken as string);
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
    if (response && response.data && response.status === 200) {
      const user = transform.getUserDetailsApiToUi(response.data);
      dispatch(storeUserDetails(user));
      dispatch(setPageUIState({ key: 'isCurrentUserDetailsFetched', value: true }));
      onSuccess(userEmail || user?.email);
      dispatch(setPageUIState({ key: 'RECENT_LOG_OUT', value: false }));
      authenticateUser(history, routeToAccess);
    }
  };
};

export const getUserPermissions = (): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const authToken = getState().auth.authToken;
    try {
      response = await get('users/permissions', authToken as string);
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
    if (response && response.data && response.status === 200) {
      const permissions = transform.getUserPermissionsApiToUi(response.data);
      dispatch(storeUserPermissions(permissions));
    }
  };
};

export const sendForgotPasswordRequest = (email: string, setSuccessMessageVisible: any, setForgotPasswordEmail?: Dispatch<SetStateAction<string>>): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const authToken = getState().auth.authToken;
    try {
      response = await get(`dashboardUsers/forgotPassword?email=${encodeURIComponent(email)}`, authToken as string);
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }

    if (response && response.data && response.status === 200) {
      setSuccessMessageVisible(true);
      setForgotPasswordEmail?.('');
    }
  };
};

export const resetPasswordRequest = (password: string, setPasswordResetSuccess: any): Thunk => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    let response = null;
    const urlParams = new URLSearchParams(window.location.search);
    const bw_enc = urlParams.get('bw_enc');
    try {
      response = response = await post('dashboardUsers/setPassword', {
        bw_enc,
        newPassword: password,
      });
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
    if (response && response.data && response.status === 200) {
      setPasswordResetSuccess(true);
    }
  };
};

export const attemptLoginWithToken = (
  history: History,
  platform: string,
  token: string,
  onFail: () => void,
  onSuccess: (email: string) => void,
  routeToAccess?: string | null,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    let response = null;
    try {
      response = await get(`loginWithPlatform?platform=${platform}&token=${token}`);
      if (response && response.data && response.data.success) {
        const token = transform.attemptLoginApiToUi(response.data);
        dispatch(setAuthToken(token));
        dispatch(isAccountsFetched(true));
        dispatch(getUserDetails(history, onSuccess, '', routeToAccess || '/login'));
        dispatch(getUserPermissions());
      }
    } catch (error: any) {
      onFail();
    }
  };
};

export const getUserDetailsForIframe = (): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const authToken = getState().auth.authToken;
    try {
      response = await get('users/currentUserDetails', authToken as string);
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
    if (response && response.data && response.status === 200) {
      const user = transform.getUserDetailsApiToUi(response.data);
      dispatch(setPageUIState({ key: 'isCurrentUserDetailsFetched', value: true }));
      dispatch(storeUserDetails(user));
    }
  };
};

export const updateLastLoggedIn = (page: string, onDone: () => void): Thunk => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const authToken = getState().auth.authToken;
    try {
      response = await post('dashboardUsers/userActivity', { lastUiActivity: { page } }, authToken as string, true);
    } catch (error: any) {}
    if (response && response.status === 204) {
      onDone();
    }
  };
};
